/* eslint-disable no-restricted-syntax */
/**
 *
 * FormikAutocomplete
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';

import Select from 'components/StyledSelect';

import messages from './messages';
import { ACTIVE_ENV } from '../../utils/activeEnv';

/**
 * Loads enumerations with name [props.entity] from BE API or uses [props.preloadedData] if present
 */

/* eslint-disable react/prefer-stateless-function */
class AutoSelect extends React.PureComponent {
  loadPromise = inputValue => {
    const appendixCallback = this.props.urlAppendixCallback;
    let appendix = '';
    if (appendixCallback) {
      const callbackResult = appendixCallback();
      if (callbackResult === false) {
        return new Promise(resolve => resolve([])); // means do not call BE (appendix not ready - typically limitting value not set yet
      }
      appendix = callbackResult;
    }
    const fullUrl = `${ACTIVE_ENV.basePathBe}/${this.props.entity}/?expression=${encodeURIComponent(
      inputValue,
    )}${appendix}${this.props.additionalQueryParams || ''}`;
    const fetchOptions = { method: 'GET', headers: { Authorization: `Bearer ${this.props.token}` } };
    try {
      return fetch(fullUrl, fetchOptions)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          throw response;
        })
        .then(results => {
          if(this.props.entity === 'plans' && this.props.excludePlans){
            return (results ? results.filter(item => this.shouldInclude(item)) : [])
          }else {
            return (results && results.results ? results.results.filter(item => this.shouldInclude(item)) : [])
          }
        })
        .then(results => {
          if (this.props.setValueAfterLoad) this.props.setValueAfterLoad(results, this.props);
          return results;
        });
    } catch (err) {
      console.log('Fetch error', err);
    }
    return new Promise(resolve => resolve([]));
  };

  shouldInclude(item) {
    if(this.props.excludePlans && !(item?.planningArea?.facilities?.length > 0 && item?.planningArea?.managementUnits?.length > 0 && item?.planningArea?.customers?.length > 0)){
      return false;
    }
    if (this.props.exclude) {
      const equalFunc = item.id ? (a, b) => a.id === b.id : (a, b) => JSON.stringify(a) === JSON.stringify(b);
      for (const excludeItem of this.props.exclude) {
        if (equalFunc(item, excludeItem)) {
          return false;
        }
      }
    }
    return true;
  }

  onChange(params) {
    if (this.props.setValue) {
      this.props.setValue(params);
    } else {
      console.log('Autocomplete value selected but no handler set', params);
    }
  }

  render() {
    const {
      preloadedData,
      value,
      intl: { formatMessage },
    } = this.props;

    const RealSelect = preloadedData ? Select : AsyncSelect;

    return (
      <RealSelect
        id={this.props.id}
        placeholder={formatMessage(this.props.placeholder || messages.placeholder)}
        maxMenuHeight={this.props.maxMenuHeight}
        classNamePrefix="react-select"
        className={`AsyncSelect ${this.props.className}`}
        isDisabled={this.props.disabled}
        defaultValue={this.props.initialValue}
        defaultOptions
        options={preloadedData}
        loadOptions={this.loadPromise}
        onInputChange={this.handleInputChange}
        onChange={params => this.onChange(params)}
        getOptionLabel={option => (this.props.getOptionLabel ? this.props.getOptionLabel(option) : option.name)}
        getOptionValue={option => option.id}
        theme={theme =>
          this.props.standalone
            ? theme
            : {
                ...theme,
                spacing: { controlHeight: 28 },
              }
        }
        isMulti={this.props.isMulti}
        menuIsOpen={this.props.menuIsOpen}
        isClearable={this.props.isClearable === true}
        value={value}
      />
    );
  }
}

const AutocompleteSelectInner = injectIntl(AutoSelect);

export const AutocompleteFormik = styled(acProps => {
  const {
    field,
    form: { touched, errors },
    ...props
  } = acProps;
  const setValue = val => field.onChange({ target: { name: field.name, value: val } });
  return (
    <div className={props.className}>
      <AutocompleteSelectInner {...field} {...props} setValue={setValue} />
      {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
  );
})``;

AutocompleteFormik.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  entity: PropTypes.string,
  className: PropTypes.string,
  setValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  urlAppendixCallback: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.object,
  exclude: PropTypes.array,
  preloadedData: PropTypes.array,
  additionalQueryParams: PropTypes.string,
  token: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  isClearable: PropTypes.bool,
  standalone: PropTypes.bool,
};

AutocompleteSelectInner.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  entity: PropTypes.string,
  className: PropTypes.string,
  setValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  urlAppendixCallback: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  onChange: PropTypes.func,
  setValueAfterLoad: PropTypes.func,
  value: PropTypes.object,
  exclude: PropTypes.array,
  preloadedData: PropTypes.array,
  additionalQueryParams: PropTypes.string,
  token: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  standalone: PropTypes.bool,
};

const AutocompleteSelect = styled(AutocompleteSelectInner)`
  .react-select__multi-value {
    background-color: ${props => props.theme.color.yellow};
    margin: 1px;
  }
  .react-select__multi-value__remove {
    cursor: pointer;
  }
`;

export default AutocompleteSelect;
