import React from 'react';
import { fromJS } from 'immutable';

import { DisplayDate, DisplayDateTime, parseDate } from '../../utils/dateTime';

export const FacilitiesCell = params => {
  const value = params.data.facilities || params.data.planningArea.facilities;
  return (
    <div>
      {value
        ? value.map(item => (
            <div key={item.id}>
              {' '}
              {item.name} - {item.code}{' '}
            </div>
          ))
        : null}
    </div>
  );
};
export const CustomersCell = params => {
  const value = params.data.customers;
  return <div>{value ? value.map(item => <div key={item.id}>{`${item.name} - ${item.code}`}</div>) : null}</div>;
};
export const ManagementUnitsCell = params => {
  const value = params.data.managementUnits || params.data.planningArea.managementUnits;
  return <div>{value ? value.map(item => <div key={item?.id}>{`${item?.description}`}</div>) : null}</div>;
};

export const ManagementUnitsValueGetter = params =>
  params.data.managementUnits ? params.data.managementUnits.map(item => (item ? item.description : '')).join(', ') : '';

export const CustomersValueGetter = params => {
  const mapper = item => `${item.code} - ${item.name}`;
  return params.data.customers ? params.data.customers.map(mapper).join(', ') : '';
};
export const CreatedByCell = params => {
  let value;
  if (params.data) {
    value = params.data.createdBy;
    if (!value) {
      value = params.data.audit ? params.data.audit.createdBy : undefined;
    }
  }
  return (
    <div>
      {value && value.firstName} {value && value.lastName}
    </div>
  );
};
export const FirstLastNameCell = key => params => {
  const value = params.data[key];
  return (
    <div>
      {value && value.firstName} {value && value.lastName}
    </div>
  );
};
export const CreatedByValueGetter = params => {
  const value = params.data.createdBy;
  return value && `${value.firstName} ${value.lastName}`;
};
export const DateCell = params => {
  const result = <DisplayDateTime value={params.value} />;
  return result;
};
export const DateNoTimeCell = params => <DisplayDate value={params.value} />;

function getFrom(object, path) {
  const val = fromJS(object).getIn(path);
  if (val !== undefined) {
    return val.toJS();
  }
  return undefined;
}

export const FirstLastNameValueGetter = key => params => {
  const value = params.data[key];
  return value ? `${value.firstName} ${value.lastName}` : '';
};

export const CreatedValueGetter = params => params.data.created || (params.data.audit && params.data.audit.created);

export const UpdatedValueGetter = params => params.data.updated || (params.data.audit && params.data.audit.updated);

// provide comparator function
export function dateComparator(filterLocalDateAtMidnight, cellValue) {
  const cellDate = parseDate(cellValue);
  if (cellDate === null) return 0;

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
}

export const FacilitiesValueGetter = params => {
  if (!params.data) return;
  const facilities = params.data.facilities || (params.data.planningArea && params.data.planningArea.facilities) || [];
  const mapper = item => `${item.name} - ${item.code}`;
  return facilities.map(mapper).join(', ');
};

export const DisableValueGetter = params => {
  const value = params.data.deleted;
  // TODO i17n
  return value === true ? 'Disabled' : 'Enabled';
};

export const IndirectValueGetter = params => {
  try {
    const {
      data: {
        activity: { indirect },
      },
    } = params;
    return indirect === true ? 'Yes' : 'No';
  } catch (e) {
    return '';
  }
};

export const UOMValueGetter = params => {
  try {
    const {
      data: {
        uom: { name },
      },
    } = params;
    return name || params.data.uom;
  } catch (e) {
    return params.value;
  }
};

export const CategoryWithVariableGetter = params => {
  try {
    const {
      data: {
        volumeCategory: { name, variableName },
      },
    } = params;
    return `${name} (${variableName})`;
  } catch (e) {
    return params.value;
  }
};

export const updateData = params => {
  const {
    node,
    colDef: { field },
    newValue,
    oldValue,
  } = params;
  if (oldValue !== newValue) {
    node.setDataValue(field, newValue);
  }
};
