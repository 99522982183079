/**
 *
 * PlanDetailToolbar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect as formikConnect } from 'formik';
import CrystalBallIcon from 'images/assets/magic-ball.png';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Button from 'components/Button';
import ViewModePopover from 'components/ViewModePopover';
import { PATHS } from 'containers/App/constants';

import { parserPlanResult, searchToData } from 'utils/url';
import { startPlanCopyAction, storePlanEdit, switchPlanToDisplayMode, resetPlanDetails, openMasterPlanDialog, closeMasterPlanDialog, storePlanDetails, openScheduleDialog, closecheduleDialog } from '../../containers/PlanDetailPage/actions';
import { selectGuiState, selectPlan, selectMasterPlanDialog, selectPlanDetailMasterPlan, selectScheduleDialog } from '../../containers/PlanDetailPage/selectors';
import { makeSelectTabsDomain } from '../../containers/Tabs/selectors';
import { openUploadModalAction } from '../../containers/UploadModal/actions';
import { withScrollWatch } from '../../utils/scroll';
import withSecurity, { PERMISSIONS, ROLES } from '../../utils/security';
import { dataToSearch } from '../../utils/url';
import { ComponentWithDirtyCheck } from '../ButtonWithDirtyCheck';
import { PlanDateRangeHeader } from '../DateRangeHeader';
import { DetailToolbarButtonsGroup, DetailToolbarButtonsWrap } from '../DetailPageShared';
import IconButton, { SaveButton } from '../IconButton';
import { TitleFixed } from '../PlanningAreaDetailToolbar';
import UserViewToggleButton from '../ToggleButton/UserViewToggleButton';
import messages from './messages';
import MasterPlanDialog from '../MasterPlanDialog';
import ScheduleDialog from '../ScheduleDialog';

const SaveAll = formikConnect(props => (
  <SaveButton
    type="submit"
    disabled={!props.formik.dirty}
    onClick={() => {
      setTimeout(props.formik.submitForm, 100);
    }}
  />
));

/* eslint-disable react/prefer-stateless-function */
class PlanDetailToolbar extends React.PureComponent {
  calculatePermissions() {
    const { plan, user } = this.props;
    const guiState = this.props.guiState.toJS();
    const isAdmin = user.user.roles.find(r => r.role === ROLES.ADMIN.name);
    const allButtons = !(guiState.isNew || guiState.copy);
    const planDeleted = plan && plan.deleted;
    const paDeleted = plan && plan.planningArea && plan.planningArea.deleted;
    const canCopy = !planDeleted && !paDeleted;
    const planEditable = plan && plan.editable;
    const canHistory = this.props.hasPerm(PERMISSIONS.HISTORY_PLAN) && planEditable;
    const canShowForecast = this.props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
    const canShowMasterPlan = this.props.hasPerm(PERMISSIONS.VIEW_MASTER_PLAN);
    const canShowSchedule = this.props.hasPerm(PERMISSIONS.VIEW_SCHEDULES);
    const canEdit =
      this.props.hasPerm(PERMISSIONS.EDIT_PLAN_DETAILS) &&
      !planDeleted &&
      (this.props.hasPerm(PERMISSIONS.EDIT_PLAN_IGNORE_RESTRICTIONS) || planEditable);
    const canUpload = canEdit;
    return { allButtons, canCopy, canHistory, canEdit, canUpload, canShowForecast, plan, canShowMasterPlan, canShowSchedule, isAdmin };
  }
  disabled = this.props && this.props.plan && this.props.plan.deleted ? this.props.plan.deleted : false;
  handleBack = () => {
    this.props.resetPlanDetails();
    this.props.history.push(PATHS.planListNoReset.replace(':reset', false),{disabled: this.disabled})
  };

  handleAssignMasterPlan = (selectedPlan)=>{
    this.props.history.push((PATHS.masterPlanDetail.replace(':id', `${selectedPlan.value}?isEdit=true`)),{data: this.props.plan.id});
  };

  handleCreateMasterPlan = () => {
    this.props.storePlanDetails(this.props.plan);
    this.props.history.push(PATHS.makeMasterPlan);
  };

  redirectSchedule = (id) => {
    //this.props.history.push((PATHS.shiftScheduleDetailId.replace(':id', `1882771801?isEdit=true`)));
    this.props.history.push((PATHS.shiftScheduleDetailId.replace(':id', `${id}?isEdit=true`)));
  }

  render() {
    const {
      allButtons,
      canCopy,
      canHistory,
      canEdit,
      canUpload,
      paDeleted,
      canShowForecast,
      plan,
      canShowMasterPlan,
      canShowSchedule, isAdmin
    } = this.calculatePermissions();
    const guiState = this.props.guiState.toJS();
    const planId = plan.id;
    const searchObject = this.props?.history?.location?.search && searchToData(this.props?.history?.location?.search, parserPlanResult);
    const fromMasterPlan = searchObject.masterPlanId;

    const handleBackToMasterPlan = () => {
      this.props.resetPlanDetails();
      this.props.history.push((PATHS.masterPlanDetail.replace(':id', `${fromMasterPlan}?isEdit=true`)))
    }
    return (
      <DetailToolbarButtonsWrap fixed={this.props.fixed}>
        <IconButton
          id="plan-detail-back"
          icon="arrow-circle-left"
          tooltip={messages.back}
          onClick={fromMasterPlan ? handleBackToMasterPlan : this.handleBack}
        />
        <TitleFixed {...this.props} />
        <div>
          <PlanDateRangeHeader />
        </div>
        <DetailToolbarButtonsGroup>
          <SaveAll />
          {allButtons && (
            <>
              {canEdit &&
                (this.props.guiState.get('edit') === true ? (
                  <ComponentWithDirtyCheck actionHandler={this.props.switchPlanToDisplayMode}>
                    {({ onClickHandler }) => (
                      <IconButton
                        icon="eye"
                        id="plan-detail-edit"
                        tooltip={messages.display}
                        onClick={e => {
                          // this.props.history.replace({
                          //   search: dataToSearch({ isEdit: false }),
                          // });
                          onClickHandler(e);
                        }}
                      />
                    )}
                  </ComponentWithDirtyCheck>
                ) : (
                  <IconButton
                    disabled = {this.props.plansLoaderFlag === true || this.props.plansLoaderFlag === undefined}
                    icon="edit"
                    id="plan-detail-edit"
                    tooltip={(this.props.plansLoaderFlag === true || this.props.plansLoaderFlag === undefined) ? messages.editLoader : messages.edit}
                    onClick={() => {
                      // this.props.history.replace({
                      //   search: dataToSearch({ isEdit: true }),
                      // });
                      this.props.storeDetailPageEdit(true);
                    }}
                  />
                ))}
              <ComponentWithDirtyCheck actionHandler={() => this.props.openUploadModalAction()}>
                {({ onClickHandler }) => (
                  <IconButton
                    icon="upload"
                    id="plan-detail-upload"
                    disabled={!canUpload}
                    tooltip={messages.upload}
                    onClick={onClickHandler}
                  />
                )}
              </ComponentWithDirtyCheck>
              <IconButton
                icon="copy"
                id="plan-detail-copy"
                disabled={!canCopy}
                title={paDeleted ? this.props.intl.formatMessage(messages.paIsDeleted) : ''}
                tooltip={messages.copy}
                onClick={() => this.props.history.push(PATHS.makeCopyPlan.replace(':id', planId))}
              />
              { this.props.hasPerm(PERMISSIONS.HISTORY_PLAN) &&
              <IconButton
                icon="history"
                id="plan-detail-history"
                disabled={!canHistory}
                tooltip={messages.history}
                onClick={() => fromMasterPlan ? this.props.history.push(`${PATHS.masterPlanhistoryPlan.replace(':id', planId)}?masterPlanId=${history?.location?.state?.masterPlan?.id || fromMasterPlan}`) : this.props.history.push(PATHS.historyPlan.replace(':id', planId))}
              />
              }
              { canShowMasterPlan &&
              <IconButton
                icon="object-group"
                id="master-plan-dialog"
                tooltip={messages.masterPlan}
                tooltipHelpAdd={messages.masterPlan}
                onClick={this.props.openMasterPlanDialog}
              />
              }
              {
                this.props.masterPlanDialog && 
                <MasterPlanDialog 
                  plan={plan}
                  masterPlanDialog={this.props.masterPlanDialog} 
                  closeMasterPlanDialog={this.props.closeMasterPlanDialog}
                  assignMasterPlan={this.handleAssignMasterPlan}
                  createMasterPlan={this.handleCreateMasterPlan}
                  selectPlanDetailMasterPlan={this.props.selectPlanDetailMasterPlan}
                />
              }
              {
                canShowSchedule &&
                <IconButton
                icon="calendar-alt"
                id="schedule-dialog"
                tooltip={messages.createSchedule}
                tooltipHelpAdd={messages.createSchedule}
                onClick={this.props.openScheduleDialog}
              />
              }
              {
                this.props.scheduleDialog &&
                <ScheduleDialog
                  plan={plan}
                  scheduleDialog={this.props.scheduleDialog} 
                  closecheduleDialog={this.props.closecheduleDialog}
                  redirectSchedule={this.redirectSchedule}
                />
              }
              <IconButton
                icon="vials"
                id="plan-detail-vials"
                tooltip={messages.result}
                onClick={() => fromMasterPlan ? 
                  this.props.history.push({
                    pathname: PATHS.masterplanplanResult,
                    search: dataToSearch({ planId, masterPlanId: history?.location?.state?.masterPlan?.id || fromMasterPlan }),
                  }) :
                  this.props.history.push({
                    pathname: PATHS.planResult,
                    search: dataToSearch({ planId }),
                  })
                }
              />
              { isAdmin && 
              <IconButton
                icon="boxes"
                id="plan-detail-interfaces"
                tooltip={messages.interfaces}
                tooltipHelp={messages.interfacesTooltipText}
                onClick={() => fromMasterPlan ? this.props.history.push(`${PATHS.masterPlanplanInterfaces.replace(':id', planId)}?masterPlanId=${history?.location?.state?.masterPlan?.id || fromMasterPlan}`) :this.props.history.push(PATHS.planInterfaces.replace(':id', planId))}
              />
              }
              {canShowForecast && (
                <IconButton
                  icon={<img alt="Forecast" src={CrystalBallIcon} width="20px" height="20px" />}
                  onClick={() => fromMasterPlan ? 
                    this.props.history.push({
                      pathname: PATHS.forecast,
                      search: dataToSearch({ planId, isEdit: !!guiState.edit, masterPlanId: history?.location?.state?.masterPlan?.id || fromMasterPlan }),
                    }) :
                    this.props.history.push({
                      pathname: PATHS.forecast,
                      search: dataToSearch({ planId, isEdit: !!guiState.edit }),
                    })
                  }
                  tooltip={messages.forecast}
                  tooltipHelp={messages.forecastTooltipText}
                  id="plan-detail-forecast"
                  tooltipIconHelp
                />
              )}
              <ViewModePopover />

              <UserViewToggleButton />
            </>
          )}
        </DetailToolbarButtonsGroup>
      </DetailToolbarButtonsWrap>
    );
  }
}

PlanDetailToolbar.propTypes = {
  history: PropTypes.object,
  guiState: PropTypes.object,
  plan: PropTypes.object,
  intl: PropTypes.object,
  title: PropTypes.string,
  storeDetailPageEdit: PropTypes.func,
  hasPerm: PropTypes.func,
  planId: PropTypes.number,
  fixed: PropTypes.bool,
  switchPlanToDisplayMode: PropTypes.func,
  resetPlanDetails: PropTypes.func,
  loadDefaultsAction: PropTypes.func,
  loadUserViewAction: PropTypes.func,
  saveConfigToFavAction: PropTypes.func,
  openUploadModalAction: PropTypes.func,
  tabsDomain: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  guiState: selectGuiState,
  plan: selectPlan,
  tabsDomain: makeSelectTabsDomain(),
  masterPlanDialog: selectMasterPlanDialog,
  scheduleDialog: selectScheduleDialog,
  selectPlanDetailMasterPlan: selectPlanDetailMasterPlan,
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        storeDetailPageEdit: storePlanEdit,
        copyPlan: startPlanCopyAction,
        switchPlanToDisplayMode,
        openUploadModalAction,
        resetPlanDetails,
        openMasterPlanDialog,
        openScheduleDialog,
        closeMasterPlanDialog,
        closecheduleDialog,
        storePlanDetails,
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, injectIntl, withConnect, withSecurity(), withScrollWatch(100))(PlanDetailToolbar);
