import React, {useEffect, useState} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {Form, Formik} from 'formik';
import {Map} from 'immutable';
import {Helmet} from 'react-helmet';
import {InjectedIntl, injectIntl} from 'react-intl';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Prompt} from 'react-router';
import { DateTime } from 'luxon';
import {bindActionCreators, compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import * as Yup from 'yup';

import Logs from './Logs';
import { makeSelectRunningApiCalls } from 'containers/App/selectors';
import BasePage from 'components/BasePage';
import FormikErrorsPopup from 'components/DetailPageShared/FormikErrorsPopup';
import MasterPlanDetailToolbar from 'components/MasterPlanDetailToolbar';
import ToggleSection from 'components/ToggleSection';
import MasterPlanSmartCodeDetails from 'components/MasterPlanSmartCodeDetails';
import {getToken} from 'containers/App/selectors';
import BasicSettings from 'containers/MasterPlanDetailPage/BasicSettings';
import {withUrl} from 'utils/api';
import injectSaga from 'utils/injectSaga';
import {fetchData} from 'utils/reduxApi';
import withSecurity, {PERMISSIONS} from 'utils/security';
import {loadSearch} from 'utils/url';
import {ApiMasterPlanDTO, ApiMasterPlanReportingRunDTO} from 'types/drep-backend.d';
import {Box, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import {validationSchema} from '../CreateMasterPlanPage';
import {
  clearMasterPlanConflict,
  clearPlanMHEAction,
  loadPlanAction,
  savePlan,
  savePlanBasicInfo,
  startNewAction,
  togglePlanSection,
  clearPlanAction,
} from './actions';
import messages from './messages';
import PlansSettings from './PlansSettings';
import RunsTable from './RunsTable';
import saga from './saga';
import {selectGuiState, selectPlan, selectPlanningParameters} from './selectors';
import Warnings from './Warnings';
import DialogNoPlansWarning from './DialogNoPlansWarning';

type Props = {
  intl: InjectedIntl;
  masterPlan: ApiMasterPlanDTO & { reportingRuns: ApiMasterPlanReportingRunDTO[] };
  title?: string;
  match: {
    params: {
      id: string;
    };
  };
  guiState: Map<string, any>;
  loadPlan: Function;
  toggleSection: Function;
  save: Function;
  history: RouteComponentProps.history;
  clearPlanAction: Function;
};

const validationSchemaMasterPlan = Yup.object().shape({
  name: Yup.string().required('Name is mandatory'),
  facilities: Yup.array().min(1, 'Facilities are mandatory').allItemsContainValue('Select a facility'),
  customers: Yup.array().min(1, 'Customers are mandatory').allItemsContainValue('Select a customer'),
  //managementUnits: Yup.array().min(1, 'Management Units are mandatory').allItemsContainValue('Select a management unit'),
  bothDatePartsRequired: Yup.object().when(['scheduleStartDay', 'scheduleStartTime'], {
    is: (val1, val2) => !!val1 !== !!val2,
    then: Yup.object().required('Both Schedule Start Date and Schedule Start Time should be specified'),
  }),
});

const PlanDetailPage: React.FC<Props> = props => {
  const {masterPlan, intl} = props;
  const urlId = Number(props.match.params.id);
  const planId = masterPlan && masterPlan.id;
  const title = masterPlan && masterPlan.name ? masterPlan.name : '';

  useEffect(() => {
    const query = loadSearch(props);
    const isEdit = query.get('isEdit') === 'true';
    if (urlId !== undefined && (!masterPlan || masterPlan.id !== urlId)) {
      props.loadPlan({id: urlId, edit: isEdit});
    }
  });

  useEffect(() => {
    const query = loadSearch(props);
    const isEdit = query.get('isEdit') === 'true';
    if (urlId !== undefined) {
      props.loadPlan({id: urlId, edit: isEdit});
    }
  }, [])

  return (
    <BasePage labelMessage={masterPlan ? messages.header : messages.headerOnly} labelValues={{name: title}} noMaxWidth>
      <Helmet>
        <title>{intl.formatMessage(messages.header, {name: title})}</title>
        <meta name="description" content="Master Plan Detail Page"/>
      </Helmet>
      {planId === urlId && <FormDetail {...props} title={title}/>}
    </BasePage>
  );
};
const FormDetail: React.FC<Props> = ({intl, guiState, title, masterPlan, save, loadPlan, history, clearPlanAction}) => {
  const editMode = (guiState && guiState.get('edit') && !masterPlan.deleted) || false;

  const token = useSelector(getToken);
  const isLoading = useSelector(makeSelectRunningApiCalls());
  const dispatch = useDispatch();
  const [plans, setPlans] = useState(null);
  const [fullPlans, setFullPlans] = useState([]);
  const [smartCodeDetails, setSmartCodeDetails] = useState(null);
  const [smartCodeErrors, setSmartCodeErrors] = useState([]);
  const [smartCodeErrorStatus, setSmartCodeErrorStatus] = useState(null);
  const [snowflakeStatus, setsnowFlakeStatus] = useState(true);
  const [omsChangeHistory, setOMSChangeHistory] = useState([]);
  const reportingRunError = masterPlan?.reportingRuns?.[0]?.status === "ERROR";  
  const smartCodeErrorStatusFlag = smartCodeErrorStatus?.[0]?.status === 'FAILED';
  const [open, setOpen] = useState(false);
  const [warningClosed, setWarningClosed] = useState(false);
  useEffect(()=>{
    let activityHasPlanPA = false;
    let differentOMSCode = false;
    let hassmartPlanActivity = false;
    const smartCodeError = [];
    smartCodeDetails?.forEach((data)=>{
      data?.mappings?.forEach((map)=>{
        if(map?.paWhereExist?.length === 0 || map?.plansWhereExist?.length === 0) activityHasPlanPA = true;
        const activityOmsCode = map?.smartPlanOMSCode?.split('-')[0]?.trim();
        const smartProdOmsCode = map?.omsSmartProd?.split('-')[0]?.trim();
        const omsSet = new Set();
        omsSet.add(activityOmsCode).add(smartProdOmsCode)
        const diffscOMSCode = omsSet.size !== 1;
        if(diffscOMSCode) differentOMSCode = true;
        if(!map?.smartPlanActivity) hassmartPlanActivity = true;
      })
      if(activityHasPlanPA){
        smartCodeError.push(`Activity doesn't exist in Plan or Planning Area for Smart Code Header ${data.headerId}.`)
        activityHasPlanPA = false;
      }
      if(differentOMSCode){
        smartCodeError.push(`Job code and Activity code have different OMS Codes for Smart Code Header ${data.headerId}.`);
        differentOMSCode = false;
      }
      if(hassmartPlanActivity){
        smartCodeError.push('Job code is not mapped to activity.');
        hassmartPlanActivity = false;
      }
    })
    setSmartCodeErrors(smartCodeError);
  },[smartCodeDetails])
  const removeplanIdData = () => {
    sessionStorage.removeItem('planIdToAddInMasterPlan');
  };

  function fetchAvailablePlans(masterPlan) {
    const currentFacilities = masterPlan.facilities.map(f => f.id);
    const currentCustomers = masterPlan.customers.map(f => f.id);
    fetchData(withUrl(`/plans/purePlans/?masterPlanId=${masterPlan.id}`).andToken(token), dispatch).then(result => {
      if (result.isOk) {
        setPlans(
          result.data
            .filter(p => {
              return p.planningArea.customers.every(c => currentCustomers.includes(c.id)) &&
                p.planningArea.facilities.every(f => currentFacilities.includes(f.id))
            })
            .sort((a, b) => (a.id < b.id ? -1 : 1))
        );
        setFullPlans(result.data);
      }
    });
  }

  // eslint-disable-next-line no-shadow
  function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  // @ts-ignore
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function fetchSmartCodeErrorStatus(masterPlan){
    fetchData(withUrl(`/masterPlan/smartCodeStatus/${masterPlan.id}`).andToken(token), dispatch).then(result => {
      if(result.isOk){
        setSmartCodeErrorStatus(result.data);
      }
   })
  }

  // eslint-disable-next-line no-shadow
  function fetchSmartCodeDetails(masterPlan) {
    fetchData(withUrl(`/smartCode/${masterPlan.id}`).andToken(token), dispatch).then(result => {
      if (result.isOk) {
        setSmartCodeDetails(result.data);
      }
    });
  }

  function fetchWarnings(){
    fetchData(withUrl(`/smartCode/snowflake-status`).andToken(token), dispatch).then(result => {
      if (result.isOk) {
        setsnowFlakeStatus(result.data);
      }
    });
  }

  function fetchOMSChangesHistory(masterPlan){
    fetchData(withUrl(`/omsChangeHistory/byMasterPlanId/${masterPlan.id}`).andToken(token), dispatch).then(result => {
      if (result.isOk) {
        setOMSChangeHistory(result.data);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setWarningClosed(true);
  }

  useEffect(()=>{
    return () =>{
      setOpen(false);
      setWarningClosed(false);
      clearPlanAction();
    }
  },[])

  useEffect(()=>{
    const noPlans = masterPlan?.plans?.length === 0;
    if(noPlans && !warningClosed && !isLoading) setOpen(true);
    if(!noPlans) setOpen(false);
    if(masterPlan?.name){
      sessionStorage.setItem('masterPlanName', masterPlan?.name);
    }
  },[masterPlan, isLoading])

  useEffect(()=>{
    return () => {
      if (history.location.state && history.location.state.createdFromMasterPlan) {
        let state = { ...history.location.state };
        delete state.createdFromMasterPlan;
        history.replace({ ...history.location, state });
    }
    }
  },[])

  useEffect(() => {
    fetchAvailablePlans(masterPlan);
    fetchOMSChangesHistory(masterPlan);
    fetchSmartCodeDetails(masterPlan);
    fetchSmartCodeErrorStatus(masterPlan);
    fetchWarnings();
  }, []);
  const filteredPlans = plans?.filter(p => !p.deleted) || [];
  const data = masterPlan?.plans?.filter((p)=>p.validTo);
  data?.sort(function (a, b) {
    if(a.validFrom === null  && a.validTo === null){
      return -1
    }
    if(typeof (b.validTo) == 'object' || typeof (a.validTo)=='object'){
      return +new Date(b.validTo).toDateString() - +new Date(a.validTo).toDateString()
    }else{
      return +new Date(b.validTo) - +new Date(a.validTo)
    }
  });
  const validityWarnings = data.filter((p, index) => index === 0 && p.validTo)?.filter((p) => DateTime.fromFormat(p?.validTo, 'yyyy-MM-dd') <= DateTime.now().minus({ days: 1 }))
  const noManagementUnitsFlag = masterPlan?.managementUnits.length === 0;
  return (
    <Formik
      initialValues={{masterPlan}}
      enableReinitialize
      validateOnChange
      validationSchema={Yup.object().shape({
        masterPlan: validationSchemaMasterPlan,
      })}
      onSubmit={(values, formikBag) => {
        save(values.masterPlan);
        formikBag.setSubmitting(false);
        fetchAvailablePlans(values.masterPlan);
        removeplanIdData();
      }}
      render={formikBag => (
        <Form>
          <MasterPlanDetailToolbar title={title} plan={formikBag.values.masterPlan} loadPlanAction={loadPlan} intl={intl}/>
          <BasicSettings formik={formikBag} editMode={editMode} loadPlanAction={loadPlan} allPlans={filteredPlans}/>
          <ToggleSection subtitleToShow={(reportingRunError || smartCodeErrors.length > 0 || smartCodeErrorStatusFlag || (snowflakeStatus === false) || (validityWarnings?.length > 0)) ? messages.warningsAvailable : ''} message={messages.warnings}>
            <Warnings reportingRunError={reportingRunError} smartCodeErrors={smartCodeErrors} smartCodeErrorStatusFlag={smartCodeErrorStatusFlag} messages={messages} snowflakeStatus={snowflakeStatus} validityWarnings={validityWarnings} noManagementUnitsFlag={noManagementUnitsFlag}/>
          </ToggleSection>
          <PlansSettings formik={formikBag} editMode={editMode} plans={plans} fullPlans ={fullPlans} history={history}/>
          <ToggleSection message={messages.smartCode} expanded>
             <MasterPlanSmartCodeDetails data={smartCodeDetails} masterPlan={formikBag.values.masterPlan} intl={intl}></MasterPlanSmartCodeDetails>
          </ToggleSection>
          <ToggleSection message={messages.logs} expanded>
              <Logs rowData={omsChangeHistory}/>
          </ToggleSection>
          <ToggleSection message={messages.runsTitle} expanded>
            <RunsTable data={formikBag?.values?.masterPlan?.reportingRuns || []} plans={filteredPlans}/>
          </ToggleSection>
          <Prompt when={formikBag.dirty} message={intl.formatMessage(messages.confirmDirty)}/>
          <FormikErrorsPopup/>
          <DialogNoPlansWarning
            open={open}
            closeDialog={handleClose}
          />
        </Form>
      )}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  masterPlan: selectPlan,
  guiState: selectGuiState,
  planningParameters: selectPlanningParameters,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      savePlanBasicInfo,
      startNewAction,
      save: savePlan,
      clearPlanMHEAction,
      clearMasterPlanConflict,
      loadPlan: loadPlanAction,
      toggleSection: togglePlanSection,
      clearPlanAction: clearPlanAction
    },
    dispatch,
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({key: 'masterPlanDetailPage', saga});

// @ts-ignore
export default compose(injectIntl, withConnect, withSecurity(PERMISSIONS.VIEW_MASTER_PLAN), withSaga)(PlanDetailPage);
