import React, { useContext, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { connect as formikConnect, FastField, Field, FormikProps, setIn } from 'formik';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled, { Theme, ThemeContext, withTheme } from 'styled-components';

import Button from 'components/Button';
import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import { useStyles } from 'components/FormikDayPicker/styled';
import StyledLabel from 'components/Label';
import Select from 'components/StyledSelect';
import { getToken } from 'containers/App/selectors';
import { smartProdRunsCopyRunSettings, smartProdRunsRequest, smartProdRunsDeleteSchedule } from 'containers/ForecastDetailPage/fetch';
import { formatDate, formatDateTime, formatTime } from 'utils/dateTime';
import { ApiSmartProdRunDTO, ApiSmartProdRunScheduleDTO, ApiSmartProdRunStatus } from 'types/drep-backend.d';

import CustomScheduleDialog, { SchedulingDTO } from '../PlanInterfaces/CustomScheduleDialog';
import messages from './messages';
import RelativePeriod from './RelativePeriod';
import SmartProdRunsTableDateRangePicker from './SmartProdRunsTableDateRangePicker';
import StartEndDate from './StartEndDate';
import { EntityEntry, ForecastForm, SmartProdPeriodTypeEnum, SmartProdRunEnum } from './types';

const SectionWithTitle = styled(props => <Box p={2} pt={1} {...props}></Box>)`
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 8px;

  h6 {
    margin-top: 4px;
    margin-bottom: 16px;
  }
`;

const CompactButton = styled(Button)`
  margin-bottom: 12px;
`;
const StyledButton = styled(Button)`
  display: inline-block;
  width: auto;
  height: auto;
  margin-right: 12px;
`;

const DetailWrapper = styled.div`
  max-width: 1440px;
  padding: 20px;
  z-index: 100;

  ${CompactButton} {
    white-space: normal;
    max-width: 120px;
    height: auto;
  }

  div > span {
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`;

const CenteredLabel = styled(StyledLabel)`
  height: 100%;
  display: table;

  > span {
    display: table-cell;
    vertical-align: middle;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 28px;
`;

const InputError = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${props => props.theme.color.red};
`;

const CheckboxInput = styled.input`
  float: left;
  margin-right: 12px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 5px;
`;

const Hr = styled.hr`
  border: none;
  margin-bottom: 20px;
`;

type CommonRunDTO = ApiSmartProdRunDTO | ApiSmartProdRunScheduleDTO;

type Props = {
  intl: InjectedIntl;
  theme: Theme;
  formik: FormikProps<ForecastForm>;
  data: CommonRunDTO;
  forecastEdit: boolean;
  allOmsCodes: EntityEntry[];
  allSmartProdSources: EntityEntry[];
  planningParametersId: number;
  handleSubmitPage: Function;
  setOpenedDetail: (detail: number) => void;
  handleReloadData: () => void;
  runType: string;
  hasAdjustments: boolean;
};

const NonEditableStatuses: ApiSmartProdRunStatus[] = ['SUCCESSFUL', 'FAILED', 'RUNNING'];

const isEditableStatus = (data: CommonRunDTO) => {
  if (!data) {
    return false;
  }
  if ('status' in data && NonEditableStatuses.includes(data.status)) {
    return false;
  }
  return true;
};

export const validateTargets = (data: CommonRunDTO, intl: InjectedIntl, useEventNameInError = false) => {
  if (!isEditableStatus(data)) {
    return false;
  }
  if (data.jobCodesRatio || data.actualProductivityRate || data.targetProductivityRate || data.planerDrivenForecast || data.jobCodeAdjustmentsEnabled) {
    return false;
  }
  if (useEventNameInError) {
    return intl.formatMessage(messages.smartProdRunsErrorSmartProdRunName, {
      smartProdRun: data.name,
      error: intl.formatMessage(messages.smartProdRunsValidateSmartProdRunEntry),
    });
  }
  return intl.formatMessage(messages.smartProdRunsValidateSmartProdRunEntry);
};

const validateOMSCodes = (data: CommonRunDTO, intl: InjectedIntl, useEventNameInError = false) => {
  if (!isEditableStatus(data)) {
    return false;
  }
  if (!data.omses || !data.omses.length) {
    if (useEventNameInError) {
      return intl.formatMessage(messages.smartProdRunsErrorSmartProdRunName, {
        smartProdRun: data.name,
        error: intl.formatMessage(messages.eventsTableSelectedOMSCodeValidation),
      });
    }
    return intl.formatMessage(messages.eventsTableSelectedOMSCodeValidation);
  }
  return undefined;
};

export const validateSmartProdRunEntry = (data: CommonRunDTO, intl: InjectedIntl, useEventNameInError = false) => {
  let errors = {};
  if (validateOMSCodes(data, intl)) {
    errors = { ...errors, omses: validateOMSCodes(data, intl, useEventNameInError) };
  }
  if (validateTargets(data, intl)) {
    errors = { ...errors, targets: validateTargets(data, intl, useEventNameInError) };
  }
  return errors;
};

const SmartProdRunsTableDetailCellRenderer: React.FC<Props> = ({
  intl,
  theme,
  formik,
  data,
  forecastEdit,
  allOmsCodes,
  allSmartProdSources,
  planningParametersId,
  handleSubmitPage,
  setOpenedDetail,
  handleReloadData,
  runType,
  hasAdjustments
}) => {
  const token: string = useSelector(getToken);
  const [schedulingDialogData, setSchedulingDialogData] = useState<SchedulingDTO>();
  const dispatch = useDispatch();
  const themeContext: { color: { yellow: string } } = useContext(ThemeContext);
  const classes = useStyles(theme, 48)();
  const editableStatus = isEditableStatus(data);
  const editable = forecastEdit && editableStatus;
  const renderField = (
    labelName,
    fieldName,
    input,
    fastField = true,
    labelWidth = undefined,
    inputWidth = undefined,
    validate = undefined,
  ) => (
    <>
      {labelName && (
        <Grid item xs={labelWidth || 4}>
          <CenteredLabel {...labelName} />
        </Grid>
      )}
      <Grid item xs={inputWidth || 8}>
        {fastField ? (
          <FastField name={fieldName} render={input} validate={validate} />
        ) : (
          <Field name={fieldName} render={input} validate={validate} />
        )}
      </Grid>
    </>
  );

  const isRequested = runType === SmartProdRunEnum.REQUEST;
  const isScheduled = runType === SmartProdRunEnum.SCHEDULE;
  const runsField = isRequested ? 'smartProdRuns' : 'smartProdScheduledRuns';

  const handleAddAllOMSCodes = () => {
    formik.setFieldValue(
      `${runsField}[${data.id}].omses`,
      allOmsCodes.map(code => ({ id: code.value })),
    );
  };

  const handleRemoveAllOMSCodes = () => {
    formik.setFieldValue(`${runsField}[${data.id}].omses`, []);
  };

  const handleDeleteSchedule = (data)=>{
    token &&
    smartProdRunsDeleteSchedule(token, dispatch,data.id).then(response => {
        if (response.isOk) {
          setOpenedDetail(null);
          handleReloadData();
        }
      });
  };

  const handleRequest = async ({ waitForSaveAll }) => {
    if (waitForSaveAll) {
      await handleSubmitPage(formik.values);
    }
    token &&
      smartProdRunsRequest(token, dispatch, planningParametersId, data.id).then(response => {
        if (response.isOk) {
          handleReloadData();
        }
      });
  };

  const handleCopyRunSettings = async ({ waitForSaveAll }) => {
    if (waitForSaveAll) {
      await handleSubmitPage(formik.values);
    }
    const runArray = isRequested ? formik.values.smartProdRuns : formik.values.smartProdScheduledRuns;
    token &&
      smartProdRunsCopyRunSettings(token, isRequested ? 'smartProdRun' : 'smartProdRun/schedule', dispatch, data.id, {
        ...(data as ApiSmartProdRunDTO),
        name: `Copy of ${waitForSaveAll ? runArray[data.id].name : data.name}`,
      }).then(response => {
        if (response.isOk) {
          setOpenedDetail(response.data.id);
          handleReloadData();
        }
      });
  };

  const requestButton = isRequested
    ? () => (
      <ComponentWithDirtyCheck
        actionPayload={{}}
        actionHandler={handleRequest}
        formikOverride={{ submitForm: () => { }, ...formik }}
      >
        {({ onClickHandler }) => (
          <StyledButton
            onClick={onClickHandler}
            color="secondary"
            disabled={
              !editableStatus || !isEmpty(validateSmartProdRunEntry(formik.values.smartProdRuns[data.id], intl))
            }
          >
            <FormattedMessage {...messages.smartProdRunsRequestButton} />
          </StyledButton>
        )}
      </ComponentWithDirtyCheck>
    )
    : null;

  const scheduleButton = () => (
    <StyledButton
      onClick={() => {
        setSchedulingDialogData({
          scheduleDateTime: formik.values.smartProdScheduledRuns[data.id].scheduleStart,
          schedulePeriod: formik.values.smartProdScheduledRuns[data.id].schedulePeriod,
        });
      }}
      color="secondary"
      disabled={!forecastEdit}
    >
      <FormattedMessage {...messages.smartProdRunsScheduleButton} />
    </StyledButton>
  );

  const unScheduleDeleteButton = () => (
    <>
      <StyledButton
        color="secondary"
        disabled={!forecastEdit || (!formik?.initialValues?.smartProdScheduledRuns[data.id]?.scheduleStart) || ((formik?.initialValues?.smartProdScheduledRuns[data.id]?.scheduleStart) && (!formik?.values?.smartProdScheduledRuns[data.id]?.scheduleStart))}
        onClick={() => {
          let newValues = { ...formik.values };
          newValues = setIn(newValues, `smartProdScheduledRuns.${data.id}.scheduleStart`, null);
          newValues = setIn(newValues, `smartProdScheduledRuns.${data.id}.schedulePeriod`, "");
          formik.setValues(newValues);
        }}
      >
        <FormattedMessage {...messages.smartProdRunsUnScheduleButton} />
      </StyledButton>
      <StyledButton
        color="secondary"
        disabled={!forecastEdit}
        onClick={()=>handleDeleteSchedule(data)}
      >
        <FormattedMessage {...messages.smartProdRunsDeleteButton} />
      </StyledButton>
    </>
  );

  const runArray = isRequested ? formik.values.smartProdRuns : formik.values.smartProdScheduledRuns;

  const periodRenderFunction = ({ titleLabel, dataType, periodType }) => {
    const dateLimits =
      dataType === 'dataSourceDates' ? { maxDate: DateTime.now().minus({ days: 1 }) } : { minDate: DateTime.now() };
    return (
      <SectionWithTitle>
        <h6>
          <StyledLabel {...titleLabel} />
        </h6>
        <Grid key={dataType} container spacing={2}>
          {isScheduled && formik.values?.smartProdScheduledRuns[data.id] && (
            <Grid item xs={12}>
              <RelativePeriod
                forecastEdit={forecastEdit}
                periodType={periodType}
                data={formik.values.smartProdScheduledRuns[data.id]}
              />
            </Grid>
          )}
          {isRequested && (
            <StartEndDate
              startFieldName={`smartProdRuns[${data.id}].${dataType}.startDate`}
              endFieldName={`smartProdRuns[${data.id}].${dataType}.endDate`}
              forecastEdit={forecastEdit}
              startDateLimits={forecastEdit ? dateLimits : undefined}
              endDateLimits={forecastEdit ? dateLimits : undefined}
              formik={formik}
              data={data}
            />
          )}
          {isRequested && (
            <SmartProdRunsTableDateRangePicker
              intl={intl}
              dataType={dataType}
              forecastEdit={editable}
              data={data as ApiSmartProdRunDTO}
            />
          )}
        </Grid>
      </SectionWithTitle>
    );
  };

  return (
    <DetailWrapper>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <span>{isRequested ? requestButton() : scheduleButton()}</span>
          {forecastEdit && (
            <span>
              <ComponentWithDirtyCheck
                actionPayload={{}}
                actionHandler={handleCopyRunSettings}
                formikOverride={{ submitForm: () => { }, ...formik }}
              >
                {({ onClickHandler }) => (
                  <StyledButton
                    onClick={onClickHandler}
                    color="secondary"
                    disabled={
                      !isEmpty(
                        validateSmartProdRunEntry(
                          isRequested
                            ? formik.values.smartProdRuns[data.id]
                            : formik.values.smartProdScheduledRuns[data.id],
                          intl,
                        ),
                      )
                    }
                  >
                    <FormattedMessage {...messages.smartProdRunsCopyRunSettingsButton} />
                  </StyledButton>
                )}
              </ComponentWithDirtyCheck>
            </span>
          )}
          {
            !isRequested && <span>{unScheduleDeleteButton()}</span>
          }
        </Grid>
        <Grid item xs={10} />
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {renderField(
              messages.smartProdRunsName,
              `${runsField}[${data.id}].name`,
              ({ field, form }) => (
                <StyledInput
                  disabled={!editable}
                  name={field.name}
                  type="text"
                  value={field.value || ''}
                  onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
                />
              ),
              true,
              2,
              4,
            )}
            <Grid item xs={6} />
            {renderField(
              messages.smartProdRunsSmartProd,
              `${runsField}[${data.id}].smartProdSourceId`,
              ({ field, form }) => (
                <StyledSelect
                  options={allSmartProdSources}
                  isDisabled={!editable}
                  onChange={option => {
                    form.setFieldValue(field.name, option.value);
                  }}
                  value={allSmartProdSources.find(o => o.value === field.value) || null}
                />
              ),
              true,
              2,
              4,
            )}
            <Grid item xs={6} />
          </Grid>
          {[
            {
              titleLabel: messages.smartProdRunsDataSourcePeriod,
              dataType: 'dataSourceDates' as 'dataSourceDates' | 'forecastDates',
              periodType: SmartProdPeriodTypeEnum.SOURCE,
            },
            {
              titleLabel: messages.smartProdRunsForecastPeriod,
              dataType: 'forecastDates' as 'dataSourceDates' | 'forecastDates',
              periodType: SmartProdPeriodTypeEnum.FORECAST,
            },
          ].map(periodRenderFunction)}
        </Grid>
        <Grid style={{ paddingTop: '77px' }} item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <SectionWithTitle>
                <h6>
                  <StyledLabel {...messages.dataToRequestTitle} />
                </h6>
                <Grid container spacing={1}>
                  {[
                    {
                      label: messages.matchDayOfWeek,
                      fieldName: `${runsField}[${data.id}].matchDayOfWeek`,
                    },
                  ].map(({ label, fieldName }, index) => (
                    <React.Fragment key={index}>
                      {renderField(
                        null,
                        fieldName,
                        ({ field, form }) => (
                          <span>
                            <CheckboxInput
                              id={fieldName}
                              disabled={!editable}
                              type="checkbox"
                              onChange={({ target }) => {
                                form.setFieldValue(fieldName, target.checked);
                              }}
                              checked={field.value}
                            />
                            <StyledLabel onClick={() => form.setFieldValue(fieldName, !field.value)} {...label} />
                          </span>
                        ),
                        true,
                      )}
                    </React.Fragment>
                  ))}
                  {validateTargets(runArray[data.id], intl) && (
                    <InputError>{validateTargets(runArray[data.id], intl)}</InputError>
                  )}
                  {[
                    {
                      label: messages.jobCodesRatio,
                      fieldName: `${runsField}[${data.id}].jobCodesRatio`,
                    },
                    {
                      label: messages.actualUPH,
                      fieldName: `${runsField}[${data.id}].actualProductivityRate`,
                    },
                    {
                      label: messages.targetUPH,
                      fieldName: `${runsField}[${data.id}].targetProductivityRate`,
                    },
                    {
                      label: messages.planerDrivenForecast,
                      fieldName: `${runsField}[${data.id}].planerDrivenForecast`,
                    },
                    {
                      label: messages.jobCodeAdjustmentsEnabled,
                      fieldName: `${runsField}[${data.id}].jobCodeAdjustmentsEnabled`,
                    }
                  ].map(({ label, fieldName }) => (
                    <React.Fragment key={fieldName}>
                      {renderField(
                        null,
                        fieldName,
                        ({ field, form }) => (
                          <span style={{ display: !hasAdjustments && field.name.includes('jobCodeAdjustmentsEnabled') ? 'none' : null }}>
                            <CheckboxInput
                              id={fieldName}
                              disabled={!editable}
                              type="checkbox"
                              onChange={({ target }) => {
                                form.setFieldValue(fieldName, target.checked);
                              }}
                              checked={field.value}
                            />
                            <StyledLabel onClick={() => form.setFieldValue(fieldName, !field.value)} {...label} />
                          </span>
                        ),
                        true,
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
              </SectionWithTitle>
            </Grid>
            <Grid item xs={5}>
              <SectionWithTitle>
                <h6>
                  <StyledLabel {...messages.requestStatusTitle} />
                </h6>
                <Grid container spacing={1}>
                  {[
                    {
                      label: messages.smartProdRunsStatus,
                      fieldName: `smartProdRuns[${data.id}].status`,
                      ignore: isScheduled,
                    },
                    {
                      label: messages.smartProdRunsCreated,
                      fieldName: `${runsField}[${data.id}].createdDate`,
                      format: formatDateTime,
                    },
                    {
                      label: messages.smartProdRunsCreatedBy,
                      fieldName: `${runsField}[${data.id}].createdBy`,
                      format: value => (value ? `${value.firstName} ${value.lastName}` : ''),
                    },
                    {
                      label: messages.smartProdRunsCalculated,
                      fieldName: `smartProdRuns[${data.id}].calculatedDate`,
                      format: formatDateTime,
                      ignore: isScheduled,
                    },
                    {
                      label: messages.smartProdRunsCalculatedBy,
                      fieldName: `smartProdRuns[${data.id}].calculatedBy`,
                      format: value => (value ? `${value.firstName} ${value.lastName}` : ''),
                      ignore: isScheduled,
                    },
                    {
                      label: messages.smartProdRunsLastRun,
                      fieldName: `smartProdScheduledRuns[${data.id}].lastRun`,
                      format: formatDateTime,
                      ignore: isRequested,
                    },
                    {
                      label: messages.smartProdRunsScheduleStartDate,
                      fieldName: `smartProdScheduledRuns[${data.id}].scheduleStart`,
                      format: formatDate,
                      ignore: isRequested,
                    },
                    {
                      label: messages.smartProdRunsScheduleStartTime,
                      fieldName: `smartProdScheduledRuns[${data.id}].scheduleStart`,
                      key: `smartProdScheduledRuns[${data.id}].scheduleStartTime`,
                      format: formatTime,
                      ignore: isRequested,
                    },
                    {
                      label: messages.smartProdRunsSchedulePeriod,
                      fieldName: `smartProdScheduledRuns[${data.id}].schedulePeriod`,
                      ignore: isRequested,
                    },
                  ]
                    .filter(it => it.ignore !== true)
                    .map(({ label, fieldName, format, key }) => (
                      <React.Fragment key={key || fieldName}>
                        {renderField(
                          label,
                          fieldName,
                          ({ field }) => (
                            <span>{format ? format(field.value) : field.value}</span>
                          ),
                          false,
                          6,
                          6,
                        )}
                      </React.Fragment>
                    ))}
                </Grid>
              </SectionWithTitle>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
          <SectionWithTitle>
            <Grid container spacing={1}>
              {renderField(
                messages.smartProdRunsOMSCodes,
                `${runsField}[${data.id}].omses`,
                ({ field, form }) => {
                  const omsValMessage = validateOMSCodes(
                    isRequested ? form.values.smartProdRuns[data.id] : form.values.smartProdScheduledRuns[data.id],
                    intl,
                  );
                  return editable ? (
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        <Select
                          name={field.name}
                          isMulti
                          onChange={options => {
                            form.setFieldValue(field.name, options ? options.map(option => ({ id: option.value })) : []);
                          }}
                          editable={editable}
                          isDisabled={!editable}
                          value={allOmsCodes?.filter(option => field.value?.find(f => f.id === option.value))}
                          options={allOmsCodes}
                          isClearable={false}
                          closeMenuOnSelect={false}
                          styles={{
                            menuList: base => ({
                              ...base,
                              maxHeight: 150,
                            }),
                            multiValue: base => ({
                              ...base,
                              color: 'black',
                              backgroundColor: themeContext?.color?.yellow,
                            }),
                          }}
                        />
                        {omsValMessage && (
                          <InputError>
                            {validateOMSCodes(
                              isRequested
                                ? form.values.smartProdRuns[data.id]
                                : form.values.smartProdScheduledRuns[data.id],
                              intl,
                            )}
                          </InputError>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <CompactButton
                          onClick={handleAddAllOMSCodes}
                          color="primary"
                          autoFocus
                          variant="contained"
                          disabled={!editable}
                        >
                          <FormattedMessage {...messages.eventsTableAddAllOMSCodes} />
                        </CompactButton>
                        <CompactButton
                          onClick={handleRemoveAllOMSCodes}
                          color="primary"
                          autoFocus
                          variant="contained"
                          disabled={!editable}
                        >
                          <FormattedMessage {...messages.eventsTableRemoveAllOMSCodes} />
                        </CompactButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <div style={{ whiteSpace: 'normal' }}>
                      {allOmsCodes
                        .filter(option => field.value?.find(f => f.id === option.value))
                        .map(item => (
                          <span className={classes.values} key={item.value}>
                            {item.label}
                          </span>
                        ))}
                    </div>
                  );
                },
                true,
                12,
                12,
              )}
            </Grid>
          </SectionWithTitle>
        </Grid>
      </Grid>
      <CustomScheduleDialog
        scheduling={schedulingDialogData}
        closeHandler={() => setSchedulingDialogData(null)}
        changeRow={scheduleData => {
          let newValues = { ...formik.values };
          newValues = setIn(newValues, `smartProdScheduledRuns.${data.id}.scheduleStart`, scheduleData.scheduleDateTime);
          newValues = setIn(newValues, `smartProdScheduledRuns.${data.id}.schedulePeriod`, scheduleData.schedulePeriod);
          formik.setValues(newValues);
          setSchedulingDialogData(null);
        }}
        headerLabel={messages.smartProdRunsSchedulingHeader}
      />
    </DetailWrapper>
  );
};

export default injectIntl(formikConnect(withTheme(SmartProdRunsTableDetailCellRenderer)));
