import React from "react";
import { WarningText, WarningRedText } from 'components/FormikTextInput';
import { FormattedMessage } from "react-intl";


const Warnings = ({reportingRunError, smartCodeErrors = [], smartCodeErrorStatusFlag, messages, snowflakeStatus, validityWarnings, noManagementUnitsFlag}) => {
    return(
        <>
        {
            reportingRunError && <WarningText><FormattedMessage {...messages.reportingRunningError}/></WarningText>
        }
        {
            validityWarnings?.length > 0 && <WarningRedText><FormattedMessage {...messages.planValidityWarning}/></WarningRedText>
        }
        {/* {
            noManagementUnitsFlag === true && <WarningText><FormattedMessage {...messages.managementUnitWarning}/></WarningText>
        } */}
        {
            snowflakeStatus === false && <WarningText><FormattedMessage {...messages.snowFlakeWarning}/></WarningText>
        }
        {
            smartCodeErrorStatusFlag && <WarningText><FormattedMessage {...messages.smartCodeStatusError}/></WarningText>
        }
        {
            smartCodeErrors?.map((sc)=>{
                return <WarningText>{sc}</WarningText>
            })
        }
        {
            (!reportingRunError &&  smartCodeErrors.length === 0 && !smartCodeErrorStatusFlag && snowflakeStatus === true ) && (validityWarnings?.length === 0) && <WarningText><FormattedMessage {...messages.noWarningsAvailable}/></WarningText>
        }
        </>
    )
}

export default Warnings;