import React from 'react';
import PropTypes from 'prop-types';
import { connect as formikConnect } from 'formik';
import CrystalBallIcon from 'images/assets/magic-ball.png';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { parserPlanResult, searchToData } from 'utils/url';
import { PaDateRangeHeader } from 'components/DateRangeHeader';
import ViewModePopover from 'components/ViewModePopover';
import { PATHS } from 'containers/App/constants';
import {
  createPlanAction,
  navigateToPLansAction,
  startPaCopyAction,
  storePaEdit,
  switchPaToDisplayMode,
  resetPlanningArea,
} from 'containers/PlanningAreaDetailPage/actions';
import { selectGuiStateForPa, selectPlanningAreaFromPa } from 'containers/PlanningAreaDetailPage/selectors';
import { loadDefaultsAction, loadUserAction, saveConfigToFavAction } from 'containers/TableControlled/actions';
import { makeSelectTabsDomain } from 'containers/Tabs/selectors';
import { openUploadModalAction } from 'containers/UploadModal/actions';
import { withScrollWatch } from 'utils/scroll';
import withSecurity, { PERMISSIONS, ROLES } from 'utils/security';
import { dataToSearch } from 'utils/url';

import { ComponentWithDirtyCheck } from '../ButtonWithDirtyCheck';
import { DetailToolbarButtonsGroup, DetailToolbarButtonsWrap } from '../DetailPageShared';
import IconButton, { SaveButton } from '../IconButton';
import UserViewToggleButton from '../ToggleButton/UserViewToggleButton';
import messages from './messages';

export const Title = styled.div`
  align-items: center;
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  line-height: 34px;
  margin-left: 20px;
  min-width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Main = styled.div`
  font-size: 18px;
  line-height: 1.1;
`;
export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.1;
`;

export const TitleFixed = props =>
  props.fixed ? (
    <Title>
      {props.tabsDomain.inTab ? (
        <>
          <Main>{props.title}</Main>
          <Subtitle>{props.tabsDomain.inTab}</Subtitle>
        </>
      ) : (
        props.title
      )}
    </Title>
  ) : (
    <Title style={{ visibility: 'hidden' }}>{props.title}</Title>
  );

TitleFixed.propTypes = {
  fixed: PropTypes.bool,
  title: PropTypes.string,
  tabsDomain: PropTypes.object,
};

/* eslint-disable react/prefer-stateless-function */
class PlanningAreaDetailToolbar extends React.PureComponent {
  calculatePermissions(guiState) {
    const { pa, user } = this.props;
    const isAdmin = user.user.roles.find(r => r.role === ROLES.ADMIN.name);
    const allButtons = !(guiState.isNew || guiState.copy);
    const paDeleted = pa ? pa.deleted : false;
    const canCopy = !paDeleted;
    const paEditable = pa ? pa.editable : false;
    const canHistory = this.props.hasPerm(PERMISSIONS.HISTORY_AREA) && paEditable;
    const canShowForecast = this.props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);
    const canEdit =
      this.props.hasPerm(PERMISSIONS.EDIT_AREA_DETAILS) &&
      !paDeleted &&
      (this.props.hasPerm(PERMISSIONS.EDIT_AREA_IGNORE_RESTRICTIONS) || paEditable);
    const canUpload = canEdit;
    return { allButtons, canCopy, canHistory, canEdit, canUpload, canCreatePlan: !paDeleted, canShowForecast, isAdmin };
  }
  disabled = (this.props && this.props.pa && this.props.pa.deleted) ? this.props.pa.deleted : false;

  handleback = () =>{
    this.props.resetPlanningArea();
    this.props.history.push(PATHS.planningAreasListNoReset.replace(':reset', false),{disabled: this.disabled})
  }

  render() {
    const { history, pa, makeDirtyHandler } = this.props;
    const searchObject = history.location.search && searchToData(history.location.search, parserPlanResult);
    const fromMasterPlan = searchObject.masterPlanId;
    const guiState = this.props.guiState.toJS();
    const {
      allButtons,
      canCreatePlan,
      canCopy,
      canHistory,
      canEdit,
      canUpload,
      canShowForecast,
      isAdmin
    } = this.calculatePermissions(guiState);
    const handleBackToMasterPlan = () => {
      this.props.history.push((PATHS.masterPlanDetail.replace(':id', `${fromMasterPlan}?isEdit=true`)))
    }

    return (
      <DetailToolbarButtonsWrap fixed={this.props.fixed}>
        <IconButton
          icon="arrow-circle-left"
          id="back"
          tooltip={{ ...messages.back }}
          onClick={makeDirtyHandler(()=> fromMasterPlan ? handleBackToMasterPlan() : this.handleback())}
        />
        <TitleFixed {...this.props} />
        <div>
          <PaDateRangeHeader />
        </div>
        <DetailToolbarButtonsGroup>
          <SaveButton
            type="submit"
            id="submit"
            disabled={!this.props.formik.dirty}
            onClick={() => {
              this.props.formik.submitForm();
            }}
          />
          {canEdit &&
            (guiState.edit === true ? (
              <ComponentWithDirtyCheck actionHandler={this.props.switchPaToDisplayMode}>
                {({ onClickHandler }) => (
                  <IconButton id="edit" icon="eye" tooltip={{ ...messages.display }} onClick={onClickHandler} />
                )}
              </ComponentWithDirtyCheck>
            ) : (
              <IconButton
                disabled = {this.props.planningAreaOverviewFlag === true || this.props.planningAreaOverviewFlag === undefined}
                icon="edit"
                id="edit"
                tooltip={(this.props.planningAreaOverviewFlag === true || this.props.planningAreaOverviewFlag === undefined) ? messages.editLoader : messages.edit}
                onClick={() => this.props.storeDetailPageEdit(true)}
              />
            ))}
          {allButtons && (
            <>
            { !this.props.createdFromMasterPlan && 
              <ComponentWithDirtyCheck actionHandler={() => this.props.openUploadModalAction()}>
                {({ onClickHandler }) => (
                  <IconButton
                    icon="upload"
                    id="upload"
                    disabled={!canUpload}
                    tooltip={messages.upload}
                    onClick={onClickHandler}
                  />
                )}
              </ComponentWithDirtyCheck>
              }
              { !this.props.createdFromMasterPlan && 
              <IconButton
                icon="plus-circle"
                id="create-plan"
                tooltip={{ ...messages.createPlan }}
                disabled={!canCreatePlan}
                onClick={makeDirtyHandler(() => {
                  history.push({
                    pathname: PATHS.makePlan,
                    search: dataToSearch({ paId: pa.id }),
                  });
                })}
              />
              }
              { !this.props.createdFromMasterPlan && 
              <IconButton
                id="show-plan"
                icon="chart-line"
                tooltip={{ ...messages.showPlans }}
                onClick={makeDirtyHandler(() => this.props.navigateToPaPlans({ data: pa }))}
              />
              }
              { !this.props.createdFromMasterPlan && 
              <IconButton
                icon="copy"
                id="copy"
                tooltip={{ ...messages.copy }}
                disabled={!canCopy}
                onClick={makeDirtyHandler(() => {
                  history.push(PATHS.makeCopyPA.replace(':id', pa.id));
                })}
              />
              }
              { !this.props.createdFromMasterPlan && 
              <IconButton
                icon="history"
                id="history"
                disabled={!canHistory}
                tooltip={{ ...messages.history }}
                //onClick={() => history.push(PATHS.historyPlanningArea.replace(':id', pa.id))}
                onClick={makeDirtyHandler(() => fromMasterPlan ? history.push(`${PATHS.masterPlanhistoryPlanningArea.replace(':id', pa.id)}?masterPlanId=${history?.location?.state?.masterPlan?.id || fromMasterPlan}`, {masterplanId: history?.location?.state?.masterPlan?.id, masterPlan: history?.location?.state?.masterPlan, createdFromMasterPlan: true}) : history.push(PATHS.historyPlanningArea.replace(':id', pa.id)))}

              />
              }
              { isAdmin &&
              <IconButton
                icon="boxes"
                id="plan-detail-interfaces"
                tooltip={messages.interfaces}
                tooltipHelp={messages.interfacesTooltipText}
                onClick={makeDirtyHandler(() => fromMasterPlan ? history.push(`${PATHS.masterPlanplanningAreaInterfaces.replace(':id', pa.id)}?masterPlanId=${history?.location?.state?.masterPlan?.id || fromMasterPlan}`, {masterplanId: history?.location?.state?.masterPlan?.id, masterPlan: history?.location?.state?.masterPlan, createdFromMasterPlan: true}) : history.push(PATHS.planningAreaInterfaces.replace(':id', pa.id)))}
              />
              }
              {canShowForecast && (
                <IconButton
                  icon={<img alt="Forecast" src={CrystalBallIcon} width="20px" height="20px" />}
                  onClick={makeDirtyHandler(() => fromMasterPlan ? 
                    history.push({
                      pathname: `${PATHS.forecast}`,
                      search: dataToSearch({ areaId: pa.id, isEdit: !!guiState.edit, masterPlanId: history?.location?.state?.masterPlan?.id || fromMasterPlan }),
                    })
                    : history.push({
                      pathname: PATHS.forecast,
                      search: dataToSearch({ areaId: pa.id, isEdit: !!guiState.edit }),
                    })
                  )}
                  tooltip={messages.forecast}
                  tooltipHelp={messages.forecastTooltipText}
                  id="plan-detail-forecast"
                />
              )}
              <ViewModePopover />
              <UserViewToggleButton />
            </>
          )}
        </DetailToolbarButtonsGroup>
      </DetailToolbarButtonsWrap>
    );
  }
}

PlanningAreaDetailToolbar.propTypes = {
  pa: PropTypes.object,
  history: PropTypes.object,
  guiState: PropTypes.object,
  storeDetailPageEdit: PropTypes.func,
  navigateToPaPlans: PropTypes.func,
  fixed: PropTypes.bool,
  hasPerm: PropTypes.func,
  switchPaToDisplayMode: PropTypes.func,
  saveConfigToFavAction: PropTypes.func,
  loadDefaultsAction: PropTypes.func,
  loadUserViewAction: PropTypes.func,
  title: PropTypes.string,
  formik: PropTypes.object,
  tabsDomain: PropTypes.object,
  openUploadModalAction: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  guiState: selectGuiStateForPa,
  pa: selectPlanningAreaFromPa,
  tabsDomain: makeSelectTabsDomain(),
});

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        storeDetailPageEdit: storePaEdit,
        copyPlanningArea: startPaCopyAction,
        navigateToPaPlans: navigateToPLansAction,
        switchPaToDisplayMode,
        createPlanAction,
        saveConfigToFavAction,
        loadDefaultsAction,
        loadUserViewAction: loadUserAction,
        openUploadModalAction,
        resetPlanningArea,
      },
      dispatch,
    ),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  injectIntl,
  withConnect,
  formikConnect,
  withSecurity(),
  withScrollWatch(100),
)(PlanningAreaDetailToolbar);
