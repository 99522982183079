import './style.css';

import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { useActionCreators, useSelectors } from 'use-redux';

import BasePage from 'components/BasePage';
import FormikErrorsPopup from 'components/DetailPageShared/FormikErrorsPopup';
import { CancelButton, SaveButton } from 'components/IconButton';
import InterfaceBasicInfo from 'components/InterfaceBasicInfo';
import Toolbar from 'components/Toolbar';
import { getToken } from 'containers/App/selectors';
import { logoutAction } from 'containers/LoginPage/actions';
import NotFoundPage from 'containers/NotFoundPage';
import { storePlan } from 'containers/PlanDetailPage/actions';
import KronosField from 'containers/PlanInterfaces/KronosField';
import KronosUploads from 'containers/PlanInterfaces/KronosUploads';
import SmartVolume from 'containers/PlanInterfaces/SmartVolume';
import { storePlanningArea } from 'containers/PlanningAreaDetailPage/actions';
import { PageSection, Section } from 'containers/PlanResultPage';
import { convertEntityWithPlanningParametersFromApi as normalizePlan, withUrl } from 'utils/api';
import { fetchData } from 'utils/reduxApi';
import withSecurity, { PERMISSIONS } from 'utils/security';

import messages from './messages';
import { getArea, getAreaIsEdit, getPlan, getPlanIsEdit } from './redux';

type PropsType = {
  intl: InjectedIntl,
  history: Object,
  dispatch: Function,
  area?: boolean,
  fixed?: boolean,
  match: {
    params: {
      id: string,
    },
  },
};

function PlanInterfaces(props: PropsType) {
  const {
    intl: { formatMessage },
    match: { params: { id } = {} } = {},
    dispatch,
    history,
    area = false,
  } = props;
  const [plan, token] = useSelectors((area && getArea) || getPlan, getToken);
  const [isEdit] = useSelectors((area && getAreaIsEdit) || getPlanIsEdit);
  const [logout, storePlanDetails] = useActionCreators(logoutAction, (area && storePlanningArea) || storePlan);
  const [masterPlans, setMasterPlans] = useState(null);
  const isForecastEditor = props.hasPerm(PERMISSIONS.VIEW_FORECAST_SECTION);

  const entity = (area && 'planningAreas') || 'plans';

  const fetchDetails = async () => {
    const fetchMasterPlans = async () => {
      if(entity === 'plans'){
        let masterPlanDetails = id && (await fetchData(withUrl(`/masterPlan/planId/${id}`).andToken(token), dispatch));
        setMasterPlans(masterPlanDetails.data);
      }
      if(entity === 'planningAreas'){
        //let masterPlanDetails = id && (await fetchData(withUrl(`/masterPlan/areaId/${id}`).andToken(token), dispatch));
        setMasterPlans([]);
      }
    }
    await fetchMasterPlans();
    let planDetails = id && (await fetchData(withUrl(`/${entity}/${id}`).andToken(token), dispatch));
    planDetails = (planDetails.isOk && normalizePlan(planDetails.data)) || null;
    storePlanDetails(planDetails);
  };

  // Fetching plan and put it to redux
  useEffect(() => {
    fetchDetails();
  }, [token]);
  const name = (plan && plan.name) || '';

  useEffect(()=>{
    return () => {
      if (history.location.state && history.location.state.createdFromMasterPlan) {
        let state = { ...history.location.state };
        delete state.createdFromMasterPlan;
        history.replace({ ...history.location, state });
    }
    }
  },[])

  async function save(formikBag, values) {
    formikBag.setSubmitting(true);
    const conf = values.planningParameters.smartShiftExportSettings;
    const payload = {
      planningParameters: {
        smartShiftExportParameters: {
          granularity: conf.granularity,
          smartShiftConfigurationId: conf.smartShiftConfiguration.id,
        },
      },
    };
    const updated = await fetchData(
      withUrl(`/${entity}/${plan.id}/saveAll`).post(payload).andTitle('Saving plan').andToken(token),
      dispatch,
    );
    toast(formatMessage((area && messages.paSaved) || messages.planSaved));
    updated.isOk && storePlanDetails(normalizePlan(updated.data));
  }

  const handleScheduleVolumeUpdate = ()=>{
    fetchDetails();
  };
  const title = formatMessage((area && messages.paHeader) || messages.header, { name });

  function renderForm(formikBag) {
    const kronos = formikBag.values && formikBag.values.planningParameters.smartShiftExportSettings;
    return (
      <Form>
        <Prompt when={formikBag.dirty} message={formatMessage(messages.confirmDirty)} />
        <Toolbar {...props} area={area} planId={plan.id} title={title} isEdit={isEdit} onBack={props.history.goBack}>
          <SaveButton disabled={!formikBag.dirty} onClick={() => formikBag.submitForm()} />
          <CancelButton disabled={!formikBag.dirty} onClick={() => formikBag.resetForm()} />
        </Toolbar>
        <PageSection>
          <InterfaceBasicInfo plan={plan} planMode={!props.area} />
        </PageSection>
        {/* <Section message={messages.kronos} expanded>
          <KronosField
            formatMessage={props.intl.formatMessage}
            token={token}
            planningParametersId={plan && plan.planningParameters && plan.planningParameters.id}
          />
        </Section> */}
        {/* {!area && (
          <KronosUploads
            ppId={plan && plan.planningParameters && plan.planningParameters.id}
            kronos={kronos}
            kronosId={kronos && kronos.smartShiftConfiguration && kronos.smartShiftConfiguration.id}
            formikBag={formikBag}
            token={token}
          />
        )} */}
        {isForecastEditor && (
          <Section message={messages.smartVolumeAssociation} expanded>
            {plan && (
              <SmartVolume
                formik={formikBag}
                plan={plan}
                fetchDetails={fetchDetails}
                storePlanDetails={storePlanDetails}
                dirty={formikBag.dirty}
                handleScheduleVolumeUpdate={handleScheduleVolumeUpdate}
                masterPlans={masterPlans}
              />
            )}
          </Section>
        )}
        <FormikErrorsPopup />
      </Form>
    );
  }

  // Login verification
  if (!token) {
    logout();
    return <span />;
  }

  if (!id) return <NotFoundPage />;

  return (
    <BasePage labelMessage={(area && messages.paBaseHeader) || messages.header} labelValues={{ name }} noMaxWidth>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Plan Interfaces" />
      </Helmet>
      {plan && (
        <Formik
          initialValues={plan}
          enableReinitialize
          validateOnChange={false}
          onSubmit={(values, formikBag) => {
            save(formikBag, values);
            formikBag.setSubmitting(false);
          }}
          render={renderForm}
        />
      )}
    </BasePage>
  );
}

const Interfaces = compose(
  withRouter,
  withSecurity(PERMISSIONS.VIEW_PLAN_DETAILS),
  connect(),
  injectIntl,
)(PlanInterfaces);

export default withSecurity(PERMISSIONS.EDIT_PLAN_DETAILS)(Interfaces);

export const PlanAreaInterfaces = withSecurity(PERMISSIONS.EDIT_AREA_DETAIL)((props: PropsType) => (
  <Interfaces {...props} area />
));
