import React from 'react';
import {FormikProps, getIn} from 'formik';
import { toNumber } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import MHEAssistant from 'containers/MHEAssistant';
import NameWithToolTip, { GroupWithToolTip } from 'components/NameWithToolTip';
import { Loading } from 'components/ResultMatrixActivitiesTable';
import { Wraptable } from 'components/ResultMatrixActivitiesTable/styled';
import Table from 'components/Table';
import GroupFilter from 'components/Table/filter/GroupFilter';
import { makeSelectRunningApiCalls } from 'containers/App/selectors';
import { cellClassFromDataset, cellClassFromUnit } from 'containers/PlanResultPage/calculation/calculateCommon';
import {
  DATASET,
  Filter as ColumnSettingsFilter, GRANULARITY,
  PlanningParameters,
  UNIT,
} from 'containers/PlanResultPage/calculation/types';
import { makeSelectTableMHE } from 'containers/PlanResultPage/selectors';
import { tableHeight, roletableHeight } from 'containers/PlanResultPage/utils';
import calendarMessages from 'utils/calendar/messages';
import { dateFormat, numberFormat } from 'utils/utils';
import {
  ApiCalculationByMhe,
  ApiCalculationMheByActivity,
  ApiComplexCalculationResultDTO,
  ApiCustomerDTO,
  ApiMheAvailability,
  ApiMheData,
  ApiMheDay,
  ApiMheHour,
  ApiMheWeek,
  ApiMheWzp,
  ApiUpdateMheCalculationValueOverride,
} from 'types/drep-backend.d';

import { ExportButton, MHEAssistantButton } from '../IconButton';
import messages from './messages';
import { LOG_RESULT } from "../../utils/logger";

var mheTypes = [];

const TotalTitle = (key = 'activity') => params => {
  const { data } = params;
  return <span>{data[key]}</span>;
};

const parseDateAndFormat = value => {
  const day = new Date(`${value}T00:00:00`);
  return dateFormat(day);
};

enum BottomDataType {
  Demand = 'DEMAND',
  Available = 'AVAILABLE',
  Discrepancy = 'DISCREPANCY',
  DiscrepancyPer = 'DISCREPANCYPER',
  Maintenance = 'MAINTENANCE',
}

const SectionTitle = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 20px;
  display: flex;
  align-items: center;

  ${ExportButton} {
    margin-left: auto;
    font-weight: normal;
  }
  ${MHEAssistantButton} {
    margin-left: 5px;
    font-weight: normal;
  }
`;

const Wrap = styled.div`
  .ag-theme-balham .ag-row.empty-row .cell-effort {
    background-color: #ffffff;
  }

  .column-budget {
    background-color: ${props => props.theme.color.blueLL};
  }

  .column-planned {
    background-color: ${props => props.theme.color.greenLL};
  }

  .column-actuals {
    background-color: ${props => props.theme.color.violetLL};
  }

  .column-forecast {
    background-color: ${props => props.theme.color.blueLight};
  }

  .cell-effort {
    background-color: rgba(223, 195, 208, 0.44);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-editable {
    background-color: ${props => props.theme.color.blue} !important;
    color: ${props => props.theme.color.white};
  }

  .cell-overridable {
    background-color: rgba(196, 223, 185, 0.44);
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable {
    color: black !important;
    background-color: rgba(223, 195, 208, 0.44) !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable-sum{
    color: black !important;
    font-weight: bold;
    background-color: rgba(223, 195, 208, 0.44) !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable-dept{
    color: black !important;
    font-weight: bold;
    background-color: white !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-adjustable-cust{
    color: black !important;
    font-weight: normal;
    background-color: white !important;
    text-align: right;
    justify-content: flex-end;
  }

  .cell-overridable .ag-header-cell-label {
    justify-content: flex-end;
  }

  .cell-adjustable .ag-header-cell-label {
    color: rgba(0, 0, 0, 0.54) !important;
    justify-content: flex-end;
  }

  .cell-adjustable-sum .ag-header-cell-label {
    color: rgba(0, 0, 0, 0.54) !important;
    justify-content: flex-end;
  }

  .negative-number {
    color: ${props => props.theme.color.red};
  }

  .positive-number {
    color: ${props => props.theme.color.greenDark};
  }

  .cell-effort .ag-header-cell-label {
    justify-content: flex-end;
  }

  .cell-nos-avg {
    justify-content: flex-end;
  }

  .sum-row {
    font-weight: bold;
  }

  .total-column {
    font-weight: bold;
  }

  div[ref='ePinnedLeftHeader'] .ag-theme-balham .ag-header-cell::after,
  .ag-theme-balham .ag-header-group-cell::after {
    border-right: none;
  }

  div[ref='ePinnedLeftHeader'] .ag-header-row:not(:nth-last-child(2)) {
    border-bottom: none;
  }
`;

type Props = {
  data: ApiComplexCalculationResultDTO;
  editing: boolean;
  intl: InjectedIntl;
  granularity: string;
  isLoading: boolean;
  columnSettings: ColumnSettingsFilter;
  planningParameters: PlanningParameters;
  result: {
    editing: boolean;
    calculationStatus: string;
    colDef: Object[];
    totals: Object[];
    byHour: boolean;
    isShift: boolean;
    planName: string;
  };
  onExport: Function;
  onSortChanged: Function;
  onFilterChanged: Function;
  onGridReady: Function;
  formik: FormikProps<unknown>;
  startDate: any;
  endDate: any;
  planId: any;
  reloadCaluculation: any;
  pa: any;
  paat: any;
  index: number;
  calculationStatus: string;
  isShiftFlag : boolean;
};

type RowDataType = {
  mhe: string;
  activity: string;
  customer: ApiCustomerDTO | null;
  department: string | null;
  labourDemand: ApiMheData | null;
  weeks: ApiMheWeek<ApiMheData>[] | null;
  shift?: string;
  shiftId?: number;
  uom: string | null;
};

const canEditGranularity = (granularity: string, isShift: boolean) =>
  (granularity === 'HOUR' && !isShift) || granularity === 'WZP';

function getDataSourcePath(granularity: string, weekIndex: number, dayIndex: number, wzpIndex: number = null, hourOfDay: number = null): string {
  let result = weekIndex !== undefined ? `weeks.${weekIndex}` : 'missingWeek'; // dummy property
  if (granularity !== GRANULARITY.WEEK && granularity !== GRANULARITY.MONTH) {
    result = dayIndex !== undefined ? `${result}.days.${dayIndex}` : 'missingDay'; // dummy property
    if (granularity !== GRANULARITY.DAY) {
      result = wzpIndex !== undefined ? `${result}.wzps.${wzpIndex}` : 'missingWzp'; // dummy property
      if (granularity !== GRANULARITY.WZP) {
        result = hourOfDay !== undefined ? `${result}.hours.${hourOfDay}` : 'missingHour'; // dummy property
      }
    }
  }
  return result;
}

const ResultMatrixMHETableSimplifiedDataModelMasterPlan: React.FC<Props> = props => {
  const [gridApi, setGridApi] = React.useState(null);
  const [modifyMHEAssistant, setModifyMHEAssistant] = React.useState<boolean>(false);
  const [mheactivityValue, setMHEActivityValue] = React.useState([]);

  React.useEffect(() => {
    return () => {
      mheTypes = []
    }
  }, []);

  const displayMHEAssistant = () => {
    if(props.paat?.dayTransformationType === "ACTIVITY"){
    if (props.paat?.transformationType == "HOUR" && (props.granularity == "WZP" || props.granularity == "MONTH")) {
      return false
    } else if (props.paat?.transformationType == "SHIFT" && (props.granularity == "HOUR" || props.granularity == "MONTH")) {
      return false;
    } else if (props.paat?.transformationType == "WZP" && props.granularity == "MONTH") {
      return false;
    } else {
      return true;
    }
  }
  if(props.paat?.dayTransformationType === "VOLUME_CATEGORY"){
    if (props.paat?.transformationType == "HOUR" && (props.granularity == "WZP" || props.granularity == "MONTH" || props.granularity == "WEEK")) {
      return false
    } else if (props.paat?.transformationType == "SHIFT" && (props.granularity == "HOUR" || props.granularity == "MONTH" || props.granularity == "WEEK")) {
      return false;
    } else if (props.paat?.transformationType == "WZP" && (props.granularity == "MONTH" || props.granularity == "WEEK")) {
      return false;
    } else {
      return true;
    }
  }
  }

  const mheAssistantFlag = displayMHEAssistant();

  const onFilterChanged = params => {
    gridApi.api.refreshCells({ force: true });
    gridApi.api.redrawRows();
    params && props.onFilterChanged && props.onFilterChanged(params);
  };

  const onGridReady = params => {
    const { result } = props;
    setGridApi(params);
    props.onGridReady(params, !!result.colDef, mheTypes);
  };

  const getRowClass = params => {
    const result = [];
    if (!params.data) {
      return '';
    }
    if (params.data.mheName === undefined) {
      result.push('empty-row');
    }

    if (params.data.isSumRow) {
      result.push('sum-row');
    }
    return result.join(' ');
  };

  const generateHeadersForDataType = (childrenFunction: (t: DATASET) => Object) => {
    const types: DATASET[] = [];
    props.columnSettings.showBudget && types.push(DATASET.budget);
    props.columnSettings.showPlanned && types.push(DATASET.planned);
    props.columnSettings.showActuals && types.push(DATASET.actuals);
    props.columnSettings.showForecast && types.push(DATASET.forecast);
    return types.map(t => ({
      headerName: formatMessage(messages[`type_${t}`]),
      headerTooltip: formatMessage(messages[`type_${t}`]),
      headerClass: `column-${t}`,
      children: childrenFunction(t),
    }));
  };

  function sumOrZero(values: number[]): number {
    return values.filter(v => v != null).reduce((sum, current) => Number(sum) + Number(current), 0);
  }

  const cellClass = (dataset: DATASET, isSum: boolean, unit: UNIT) => (params): string => {
    const { data, value, node } = params;
    if (!data) {
      if (node.group) {
        return `${unit}_${node.field}_sum`;
      }
      return;
    }
    const result = [];
    const isEditable =
      !isSum &&
      props.editing &&
      canEditGranularity(props.granularity, props.result.isShift) &&
      !params.node.group &&
      dataset === DATASET.actuals &&
      params.data &&
      params.data.bottomDataType === BottomDataType.Maintenance;
    result.push(cellClassFromUnit(unit));
    result.push(cellClassFromDataset(dataset));
    result.push(isEditable ? 'cell-editable' : '');
    result.push(isEditable ? 'cell-overridable' : '');

    if ((params.data?.bottomDataType === BottomDataType.Discrepancy) || params.data?.bottomDataType === BottomDataType.DiscrepancyPer) {
      if (Number(params.value) >= 0) {
        result.push('positive-number');
      } else {
        result.push('negative-number');
      }
    }

    if (value === undefined) {
      result.push(`cell-empty`);
    }
    if (isSum) {
      result.push('total-column');
    }
    return result.join(' ');
  };

  const granularityText = () => {
    const {
      granularity,
      intl,
      isShiftFlag
    } = props;
    const isShiftTransformationType = isShiftFlag//props.planningParameters.transformationType === 'SHIFT';
    if (granularity === GRANULARITY.MONTH) return intl.formatMessage(messages.month);
    if (granularity === GRANULARITY.WEEK) return intl.formatMessage(messages.week);
    if (granularity === GRANULARITY.DAY) return intl.formatMessage(messages.day);
    if (granularity === GRANULARITY.WZP) return intl.formatMessage(isShiftTransformationType ? messages.shift : messages.wzp);
    if (granularity === GRANULARITY.HOUR) return intl.formatMessage(messages.hour);
    return '?';
  };

  const getColDefs = () => {
    const commonFieldAttributes = (
      dataset: DATASET,
      weekIndex?: number,
      dayIndex?: number,
      wzpIndex?: number,
      hourOfDay?: number,
    ) => ({
      width: 65,
      sortable: false,
      suppressMenu: true,
      valueFormatter: params => {
        if(params?.data?.bottomDataType === BottomDataType.DiscrepancyPer){
          const val = Number(params.value).toFixed(2);
          if(params.value === ''){
            return '';
          }
          if(Math.abs(params.value) === 100){
            return `${params.value}%`
          }
          if(val == '0.00'){
            return '0%'
          }
          return `${val}%`
        }
        if (!params.value || params.value === 0) {
          return params.value;
        }
        const val = Number(params.value).toFixed(2);
        return numberFormat(val, { minimumFractionDigits: 2 });
      },
      cellClass: cellClass(dataset, false, UNIT.e),
      headerClass: 'cell-effort',
      aggFunc: sumOrZero,
      editable: params => {
        const ret =
          props.editing &&
          !params.node.group &&
          dataset === DATASET.actuals &&
          params.data &&
          canEditGranularity(props.granularity, props.result.isShift) &&
          params.data.bottomDataType === BottomDataType.Maintenance;
        return ret;
      },
      newValueHandler: params => {
        const editedCellValue = params.newValue === '' ? null : Number(params.newValue);
        const oldValue = params.oldValue === '' ? null : Number(params.oldValue);
        if (Number.isNaN(editedCellValue) || editedCellValue === oldValue) {
          return false;
        }

        let dataSource = params.data.weeks[weekIndex];
        let dataKey = `w${weekIndex}`;
        let overrideDay = params.data.weeks[weekIndex].startDay;
        if (dayIndex !== undefined) {
          dataSource = params.data.weeks[weekIndex]?.days[dayIndex] || {};
          overrideDay = params.data.weeks[weekIndex]?.days[dayIndex].day;
          dataKey = `${dataKey}d${dayIndex}`;
          if (wzpIndex !== undefined) {
            dataSource = params.data.weeks[weekIndex]?.days[dayIndex]?.wzps[wzpIndex] || {};
            dataKey = `${dataKey}s${wzpIndex}`;
            if (hourOfDay !== undefined) {
              dataSource =
                params.data.weeks[weekIndex]?.days[dayIndex]?.wzps[wzpIndex]?.hours.find(
                  it => it.hourOfDay === hourOfDay,
                ) || {};
              dataKey = `${dataKey}h${hourOfDay}`;
            }
          }
        }
        if (params.data.bottomDataType === BottomDataType.Maintenance) {
          dataSource.data.maintenanceOverride[dataset] = editedCellValue;
          const overrideEntry: ApiUpdateMheCalculationValueOverride = {
            day: overrideDay,
            workZonePeriodId:
              wzpIndex === undefined
                ? null
                : params.data.weeks[weekIndex]?.days[dayIndex]?.wzps[wzpIndex].workZonePeriodId,
            hourOfDay: hourOfDay === undefined ? null : dataSource.hourOfDay,
            // mheId: dataSource.mhe????
            mheId: params.data.mheId,
            value: editedCellValue,
          };
          // console.log(`Setting key mheOverrides.${dataKey} to`, overrideEntry);
          formik.setFieldValue(`mheOverrides.${dataKey}`, overrideEntry);
        }

        return true;
      },
      valueGetter: params => {
        const dsForGetter = dataset === DATASET.budget ? 'baseline' : dataset;
        // console.log(`Weekindex ${weekIndex}, dayIndex ${dayIndex}, wzpIndex ${wzpIndex}, hourIndex${hourIndex}, params`, params)
        let dataSource;
        let dataSources = []; // for isShift when hours can overlap
        if (granularity === GRANULARITY.HOUR) {
          const wzpDataSourcePath = getDataSourcePath(GRANULARITY.WZP, weekIndex, dayIndex, wzpIndex)
          const wzpDataSource = getIn(params.data, wzpDataSourcePath, { hours: [] });
          dataSource = wzpDataSource.hours.find(
              it => it.hourOfDay === hourOfDay,
            ) || {};
          if (isShift) {
            const daysDataSource = getIn(params.data, getDataSourcePath(GRANULARITY.DAY, weekIndex, dayIndex));
            // shifts can overlap so we need sum hour entries for all shifts
            dataSources = daysDataSource?.wzps
              .flatMap(it => it.hours || [])
              .filter(it => it.hourOfDay === hourOfDay) || [];
          }
        } else {
          const dataSourcePath = getDataSourcePath(granularity, weekIndex, dayIndex, wzpIndex, hourOfDay);
          dataSource = getIn(params.data, dataSourcePath);
        }
        if (!dataSource) {
          // for some activities wee may not arrive so datasource would be undefined SMP-2780
          // this happens for more periods if MHE is defined only in some period
          return '';
        }
        let available = (dataSource.data?.available || {})[dsForGetter];
        if (isShift) {
          switch (dataSources.length) {
            case 0:
              available = 0;
              break;
            case 1:
              available = (dataSources[0].data?.available || {})[dsForGetter] || 0;
              break;
            default: {
              // go through overlapping shifts
              let availableForTheHourWithoutMaintenance;
              let maintenanceForTheHour = 0;
              dataSources.forEach(ds => {
                const availableForShift = (ds.data?.available || {})[dsForGetter] || 0;
                const maintenanceForShift = (ds.data?.maintenance || {})[dsForGetter] || 0;
                const availableForTheHourAndShiftWithoutMaintenance = availableForShift + maintenanceForShift;
                maintenanceForTheHour += maintenanceForShift;
                if (availableForTheHourWithoutMaintenance === undefined) {
                  availableForTheHourWithoutMaintenance = availableForTheHourAndShiftWithoutMaintenance;
                } else if (availableForTheHourWithoutMaintenance !== availableForTheHourAndShiftWithoutMaintenance) {
                  const wzpData = params.data.weeks[weekIndex]?.days[dayIndex]?.wzps[wzpIndex];
                  LOG_RESULT.d(
                    `Warn: for hour ${hourOfDay} unexpected total mhe availability in shift ${wzpData.workZonePeriodName}`,
                  );
                  LOG_RESULT.d(
                    `  got ${availableForTheHourAndShiftWithoutMaintenance} but expected (from other overlapping shifts) ${availableForTheHourWithoutMaintenance}. Using the bigger.`,
                  );
                  if (availableForTheHourAndShiftWithoutMaintenance > availableForTheHourWithoutMaintenance) {
                    availableForTheHourWithoutMaintenance = availableForTheHourAndShiftWithoutMaintenance;
                  }
                }
              });
              available = availableForTheHourWithoutMaintenance - maintenanceForTheHour;
            }
          }
        }
        if (params.data.bottomDataType === BottomDataType.Available) {
          return available || '0';
        }
        if (params.data.bottomDataType === BottomDataType.Maintenance) {
          const maintenanceGetter = it =>
            it.data?.maintenanceOverride[dsForGetter] !== undefined
              ? it.data?.maintenanceOverride[dsForGetter]
              : it.data?.maintenance[dsForGetter] || '0';
          if (isShift) {
            return dataSources
              .map(maintenanceGetter)
              .map(it => toNumber(it))
              .reduce((a, b) => a + b, 0);
          }
          return maintenanceGetter(dataSource);
        }
        if (
          params.data.bottomDataType === BottomDataType.Demand ||
          params.data.bottomDataType === BottomDataType.Discrepancy ||
          params.data.bottomDataType == BottomDataType.DiscrepancyPer
        ) {
          const mheName = params.data.mhe;
          let sum = 0;
          params?.api?.forEachNodeAfterFilter((rowNode, index) => {
            if (rowNode.data?.mhe === mheName) {
              let rowData = getIn(rowNode.data, `weeks.${weekIndex}`, {});
              if (dayIndex !== undefined) {
                rowData = getIn(rowNode.data, `weeks.${weekIndex}.days.${dayIndex}`, {});
                if (wzpIndex !== undefined) {
                  rowData = getIn(rowNode.data, `weeks.${weekIndex}.days.${dayIndex}.wzps.${wzpIndex}`, {});
                  if (hourOfDay !== undefined) {
                    rowData =
                      getIn(rowNode.data, `weeks.${weekIndex}.days.${dayIndex}.wzps.${wzpIndex}.hours`, []).find(
                        it => it.hourOfDay === hourOfDay,
                      ) || {};
                  }
                }
              }
              sum += rowData?.data?.[dsForGetter] || 0;
            }
          });

          if (params.data.bottomDataType === BottomDataType.Demand) {
            return sum;
          }
          if ((available || 0) - sum < 0 && params.data.bottomDataType == "DISCREPANCY") {
            const alreadyExists = mheTypes.some((m) => m.value == params.data.mhe);
            if (!alreadyExists) {
              mheTypes.push({ value: params.data.mhe, label: params.data.mhe, key: params.data.mhe })
            }
          }
          if(params.data.bottomDataType == BottomDataType.DiscrepancyPer){
            if(available && sum === 0){
              return 100;
            }
            if((available === 0 || available === undefined) && sum === 0){
              return 0;
            }
            return (((available || 0) - sum) /sum) * 100;
          }
          return (available || 0) - sum;
        }
        return {
          mhe: params.data.mhe.name,
          dataset: dsForGetter,
          toString: () => dataSource?.data?.[dsForGetter] || '0',
        };
      },
      tooltipValueGetter: params => `${intl.formatMessage(messages.fullValue)}: ${params.value}`,
    });
    const {
      result: { colDef },
      granularity,
      data,
      intl,
    } = props;
    const isShiftTransformationType = true//props.planningParameters.transformationType === 'SHIFT';
    const granularityUnitMessage = granularityText();

    const regularChildrenOfDay = (day: ApiMheDay<ApiMheData>, weekIndex: number, dayIndex: number) => {
      if (granularity === GRANULARITY.WZP || granularity === GRANULARITY.HOUR) {
        const emptyWzp: ApiMheWzp<ApiMheData> = {
          workZonePeriodId: -1,
          workZonePeriodName: '',
          data: {
            actuals: null,
            baseline: null,
            planned: null,
            forecast: null,
          },
          hours: null,
        };
        const wzps: ApiMheWzp<ApiMheData>[] = day.wzps?.length ? day.wzps : [ emptyWzp ];
        return wzps.map((wzp: ApiMheWzp<ApiMheData>, wzpIndex) => ({
            headerName: wzp.workZonePeriodName,
          headerTooltip: wzp.workZonePeriodName,
            children: wzp.hours?.length
              ? wzp.hours.map((hour: ApiMheHour<ApiMheData>) => ({
                headerName: `${hour.hourOfDay} h`,
              headerTooltip: `${hour.hourOfDay} h`,
                children: generateHeadersForDataType((dataset: DATASET) => [
                  {
                    headerName: intl.formatMessage(messages.h),
                  headerTooltip: intl.formatMessage(messages.h),
                    ...commonFieldAttributes(dataset, weekIndex, dayIndex, wzpIndex, hour.hourOfDay),
                  },
                ]),
              }))
              : generateHeadersForDataType((dataset: DATASET) => [
                {
                  headerName: intl.formatMessage(isShiftTransformationType ? messages.hShift : messages.hWzp),
                  headerTooltip: intl.formatMessage(isShiftTransformationType ? messages.hShift : messages.hWzp),
                  ...((wzp.workZonePeriodId === -1) ? { width: 65 } : commonFieldAttributes(dataset, weekIndex, dayIndex, wzpIndex)),
                },
              ]),
          }))
      }
      return generateHeadersForDataType((dataset: DATASET) => [
        {
          headerName: intl.formatMessage(messages.hDay),
          headerTooltip: intl.formatMessage(messages.hDay),
          ...commonFieldAttributes(dataset, weekIndex, dayIndex),
        },
      ])
    };

    const shiftChildrenOfDay = (day: ApiMheDay<ApiMheData>, weekIndex: number, dayIndex: number) => {
      if (!day.wzps?.length) {
        return generateHeadersForDataType((dataset: DATASET) => [
          {
            headerName: intl.formatMessage(messages.hDay),
            headerTooltip: intl.formatMessage(messages.hDay),
            ...commonFieldAttributes(dataset, weekIndex, dayIndex),
          },
        ]);
      }
      const hourColumns = [...new Set(day.wzps?.flatMap(shift => shift.hours).map(it => it.hourOfDay))];
      return hourColumns.map(hour => ({
        headerName: `${hour} h`,
        headerTooltip: `${hour} h`,
        children: generateHeadersForDataType((dataset: DATASET) => [
          {
            headerName: intl.formatMessage(messages.h),
            headerTooltip: intl.formatMessage(messages.h),
            ...commonFieldAttributes(dataset, weekIndex, dayIndex, 0, hour), // wzpIndex can be 0
            // because there will be always only 1 wzp in the row when isShift == true (see moveShiftsToRows)
          },
        ]),
      }));
    };

    let colDefs = cloneDeep(colDef);
    if (colDefs && colDefs.length > 4) {
      colDefs = colDefs.slice(0, 5);

      const byActivity = data?.mhe?.byActivity.length ? data?.mhe?.byActivity[0] : undefined;
      if (byActivity?.weeks?.length) {
        colDefs.push(
          ...byActivity.weeks.map((week: ApiMheWeek<ApiMheData>, weekIndex) => ({
            headerName: `${granularity === 'MONTH' ? intl.formatMessage(messages.month) : intl.formatMessage(messages.week)
              } ${parseDateAndFormat(week.startDay)}`,
              headerTooltip: `${granularity === 'MONTH' ? intl.formatMessage(messages.month) : intl.formatMessage(messages.week)
              } ${parseDateAndFormat(week.startDay)}`,
            children: week.days?.length
              ? week.days.map((day: ApiMheDay<ApiMheData>, dayIndex) => ({
                headerName: `${intl.formatMessage(calendarMessages[day.dayOfWeek.toLowerCase()])} ${parseDateAndFormat(
                  day.day,
                )}`,
                headerTooltip: `${intl.formatMessage(calendarMessages[day.dayOfWeek.toLowerCase()])} ${parseDateAndFormat(
                  day.day,
                )}`,
                children: props.result.isShift
                  ? shiftChildrenOfDay(day, weekIndex, dayIndex)
                  : regularChildrenOfDay(day, weekIndex, dayIndex),
              }))
              : generateHeadersForDataType((dataset: DATASET) => [
                {
                  headerName: `h/${granularity === GRANULARITY.MONTH ? intl.formatMessage(messages.month) : intl.formatMessage(messages.week)
                    }`,
                    headerTooltip: `h/${granularity === 'MONTH' ? intl.formatMessage(messages.month) : intl.formatMessage(messages.week)
                  }`,
                  ...commonFieldAttributes(dataset, weekIndex),
                },
              ]),
          })),
        );
      }

      colDefs.push({
        headerName: 'Total',
        headerTooltip: 'Total',
        children: generateHeadersForDataType((dataset: DATASET) => [
          {
            headerName: `${intl.formatMessage(messages.laborDemandSum)}/${granularityUnitMessage.toLowerCase()}`,
            headerTooltip: `${intl.formatMessage(messages.laborDemandSum)}/${granularityUnitMessage.toLowerCase()}`,
            headerClass: 'cell-effort',
            ...commonFieldAttributes(dataset),
            editable: false,
            cellClass: cellClass(dataset, true, UNIT.e),
            valueGetter: params => {
              const dsForGetter = dataset === DATASET.budget ? 'baseline' : dataset;
              if (params.data.bottomDataType) {
                const sumData = {
                  [BottomDataType.Available]: 0,
                  [BottomDataType.Maintenance]: 0,
                };
                let sum = 0;
                params.data.weeks.forEach((week: ApiMheWeek<ApiMheAvailability>) => {
                  sum += granularity === 'MONTH' || granularity === 'WEEK' ? week.data?.available[dsForGetter] || 0 : 0;
                  week.days?.forEach(day => {
                    sum += (granularity === 'DAY' && day.data.available[dsForGetter]) || 0;
                    if (isShift && granularity === 'HOUR') {
                      // because possible overlapping shifts, we need to iterate by hours over wzps
                      for (let hourOfDay = 0; hourOfDay < 24; hourOfDay++) {
                        const hourValues = day.wzps?.flatMap(wzp => wzp.hours)?.filter(it => it.hourOfDay === hourOfDay);
                        // for multiple (overlaps) pick minimal - that would be the one with maintenance
                        // if there's no maintenance, values for same hour will be the same
                        const hourValue = hourValues.length
                          ? hourValues
                            .map(it => it.data.available[dsForGetter])
                            .reduce((a, b) => Math.min(a, b), 1000000)
                          : 0;
                        sum += hourValue;
                      }
                    } else {
                      day.wzps?.forEach(wzp => {
                        sum += (granularity === 'WZP' && wzp?.data.available[dsForGetter]) || 0;
                        wzp.hours?.forEach(hour => {
                          sum += (granularity === 'HOUR' && hour.data.available[dsForGetter]) || 0;
                        });
                      });
                    }
                  });
                });
                sumData[BottomDataType.Available] = sum;

                sum = 0;
                params.data.weeks.forEach((week: ApiMheWeek<ApiMheAvailability>) => {
                  sum +=
                    granularity === 'MONTH' || granularity === 'WEEK'
                      ? week.data?.maintenanceOverride[dsForGetter] || week.data?.maintenance[dsForGetter] || 0
                      : 0;
                  week.days?.forEach((day, i) => {
                    sum +=
                      granularity === 'DAY'
                        ? day.data?.maintenanceOverride[dsForGetter] || day.data?.maintenance[dsForGetter] || 0
                        : 0;
                    day.wzps?.forEach(wzp => {
                      sum +=
                        granularity === 'WZP'
                          ? wzp.data?.maintenanceOverride[dsForGetter] || wzp.data?.maintenance[dsForGetter] || 0
                          : 0;
                      wzp.hours?.forEach(hour => {
                        sum +=
                          granularity === 'HOUR'
                            ? hour.data?.maintenanceOverride[dsForGetter] || hour.data?.maintenance[dsForGetter] || 0
                            : 0;
                      });
                    });
                  });
                });
                sumData[BottomDataType.Maintenance] = sum;

                if (params.data.bottomDataType === BottomDataType.Available) {
                  return sumData[BottomDataType.Available];
                }
                if (params.data.bottomDataType === BottomDataType.Maintenance) {
                  return sumData[BottomDataType.Maintenance];
                }

                if (
                  params.data.bottomDataType === BottomDataType.Demand ||
                  params.data.bottomDataType === BottomDataType.Discrepancy ||
                  params.data.bottomDataType === BottomDataType.DiscrepancyPer
                ) {
                  const mheName = params.data.mhe;

                  let sum = 0;
                  params?.api?.forEachNodeAfterFilter(rowNode => {
                    if (rowNode.data?.mhe === mheName) {
                      rowNode.data.weeks.forEach((week: ApiMheWeek<ApiMheAvailability>) => {
                        if (granularity === 'MONTH' || granularity === 'WEEK') {
                          sum += week.data?.[dsForGetter] || 0;
                        } else {
                          week.days?.forEach(day => {
                            if (granularity === 'DAY') {
                              sum += day.data?.[dsForGetter] || 0;
                            } else {
                              day.wzps?.forEach(wzp => {
                                if (granularity === 'WZP') {
                                  sum += wzp?.data?.[dsForGetter] || 0;
                                } else {
                                  wzp.hours?.forEach(hour => {
                                    sum += hour.data?.[dsForGetter] || 0;
                                  });
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });

                  if (params.data.bottomDataType === BottomDataType.Demand) {
                    return sum;
                  }
                  if(params.data.bottomDataType === BottomDataType.Discrepancy){
                  return (sumData[BottomDataType.Available] || 0) - sum;
                  }
                  if(params.data.bottomDataType === BottomDataType.DiscrepancyPer){
                    if((sumData[BottomDataType.Available]) && sum === 0){
                      return 100;
                    }
                    if((sumData[BottomDataType.Available] === 0) && sum === 0){
                      return 0;
                    }
                    return (((sumData[BottomDataType.Available] || 0) - sum) / sum) * 100;
                  }
                }

                return '-1';
              }
              let sum = 0;
              params.data.weeks.forEach((week: ApiMheWeek<ApiMheData>) => {
                if (granularity === GRANULARITY.MONTH || granularity === GRANULARITY.WEEK) {
                  sum += (week.data ? week.data[dsForGetter] : 0) || 0;
                } else {
                  week.days?.forEach(day => {
                    if (granularity === 'DAY') {
                      sum += day.data[dsForGetter] || 0;
                    } else {
                      day.wzps?.forEach(wzp => {
                        if (granularity === 'WZP') {
                          sum += wzp?.data[dsForGetter] || 0;
                        } else {
                          wzp.hours?.forEach(hour => {
                            sum += hour.data[dsForGetter] || 0;
                          });
                        }
                      });
                    }
                  });
                }
              });
              return `${sum}`;
            },
          },
        ]),
      });
    }
    return colDefs;
  };

  const moveShiftsToRows = (rows: RowDataType[]) => {
    const res: RowDataType[] = [];
    rows.forEach(row => {
      // separate every shift (wzp) to dedicated row
      const shiftsInRow = row.weeks?.flatMap(w => w.days || [])?.flatMap(d => d.wzps || []);
      const uniqueShifts = [...new Map(shiftsInRow.map(item => [item.workZonePeriodId, item])).values()];
      if (uniqueShifts.length === 0) {
        res.push(row);
      } else {
        uniqueShifts.forEach(shift => {
          const rowWithThisShift: RowDataType = {
            ...row,
            weeks: row.weeks.map(w => ({
              ...w,
              days: w.days?.map(d => ({
                ...d,
                wzps: d.wzps?.filter(it => it.workZonePeriodId === shift.workZonePeriodId),
              })),
            })),
          };
          res.push({ ...rowWithThisShift, shift: shift.workZonePeriodName, shiftId: shift.workZonePeriodId });
        });
      }
    });
    return res;
  };

  const {
    result,
    intl: { formatMessage },
    granularity,
    isLoading,
    onSortChanged,
    formik,
    startDate,
    endDate,
    planId,
    index,
    calculationStatus
  } = props;
  const colDefs = getColDefs();
  const { isShift, planName } = result;
  const granularityTextValue = granularityText();

  const rowData: RowDataType[] = props.data?.mhe?.byActivity.map((row: ApiCalculationMheByActivity) => {
    const uomPart = row.uom?.name ? row.uom?.name : `h/${granularityTextValue}`;
    return {
      ...row,
      department: row.department ? row.department.name : formatMessage(messages.allDepartments),
      activity: `${row.activity?.name} (${uomPart})|| ${row.activity?.regionalConfigurationName}`,
      mhe: row.mhe.name,
      activityDetails: row,
      uom: row.uom?.name,
    };
  });

  const activityrowData = props.data?.plan?.activities?.map((row) => {
     const uomPart = row.uom?.name ? row.uom?.name : `h/${granularityTextValue}`;
    return {
      ...row,
      department: row.department ? row.department.name : formatMessage(messages.allDepartments),
      activity: `${row.activity?.name} (${uomPart})`,
      mhe: row?.mhes[0]?.mhe.name,
      activityDetails: row,
      uom: row.uom?.name,
    };
  }
  );
  const newRowData = isShift ? moveShiftsToRows(rowData) : rowData;
  const activityData = activityrowData?.map((data) => {
    const activityValue = data.activity.split(' ||')
    return { value: activityValue[0], label: activityValue[0], mhe: data.mhe, key: `${data.mhe}-${activityValue[0]}`, data: data }
  });

  const pinnedBottomRowData = [];
  const sortByDate = (a,b) => (a.startDay > b.startDay) ? 1 : ((b.startDay > a.startDay) ? -1 : 0);
  props.data?.mhe?.byMhe?.forEach((row: ApiCalculationByMhe) => {
    pinnedBottomRowData.push({
      ...row,
      weeks: row.weeks.sort(sortByDate),
      mhe: row.mhe.name,
      shift: row.mhe.name,
      isSumRow: true,
      bottomDataType: BottomDataType.Demand,
      activity: `Demand`,
    });
    pinnedBottomRowData.push({
      ...row,
      mhe: row.mhe.name,
      shift: row.mhe.name,
      bottomDataType: BottomDataType.Available,
      activity: `Available`,
    });
    pinnedBottomRowData.push({
      ...row,
      mhe: row.mhe.name,
      shift: row.mhe.name,
      bottomDataType: BottomDataType.Discrepancy,
      activity: `Discrepancy`,
    });
    pinnedBottomRowData.push({
      ...row,
      mhe: row.mhe.name,
      shift: row.mhe.name,
      bottomDataType: BottomDataType.DiscrepancyPer,
      activity: `Discrepancy %`,
    });
    pinnedBottomRowData.push({
      ...row,
      mhe: row.mhe.name,
      shift: row.mhe.name,
      mheId: row.mhe.id,
      bottomDataType: BottomDataType.Maintenance,
      activity: `Maintenance`,
    });
  });

  const tLength = (pinnedBottomRowData && pinnedBottomRowData.length) || 0;
  const aproxHeight = roletableHeight(result, tLength, true, granularity, result.byHour, true);
  //const { calculationStatus } = result;
  let groupHeaderName = `${formatMessage(messages.department)} \u2192 ${formatMessage(
    messages.customer,
  )} \u2192  ${formatMessage(messages.activity)} `;
  const filterColumns = ['department', 'customer', 'activity'];
  if (result.byHour) {
    groupHeaderName = `${groupHeaderName} \u2192 ${formatMessage(messages.mhe)} `;
    filterColumns.push('mhe');
  }

  const handleMheAssistant = () => {
    setModifyMHEAssistant(true);
  };

  const handleCloseModifyMultiple = () => {
    setModifyMHEAssistant(false);
  };

  const handleMHEActivityData = (val) => {
    const actData = activityData.filter((a) => a.mhe == val);
    setMHEActivityValue(actData)
  }
  //@ts-ignore
  const mheName = props?.data?.name;
  return (
    <Wrap>
      <SectionTitle>
      <span>{`Material Handling Equipment for ${mheName}`}</span>
        <ExportButton onClick={props.onExport} />
        {mheAssistantFlag && <MHEAssistantButton onClick={handleMheAssistant} />}
        <MHEAssistant
          granularity={granularity}
          open={modifyMHEAssistant}
          closeHandler={handleCloseModifyMultiple}
          startDate={startDate}
          endDate={endDate}
          planId={planId}
          activityData={activityData}
          mheData={mheTypes}
          mheIdData={props?.data?.mhe}
          reloadCaluculation={props.reloadCaluculation}
          transformationType = {props.paat?.transformationType}
        />
      </SectionTitle>
      <Wraptable className={`ag-theme-balham ${(result.byHour && 'shift') || ''}`} aproxHeight={aproxHeight}>
        {calculationStatus === 'notStarted' ? (
          <Loading status="notStarted" />
        ) : (
          <>
            <Table
              name="resultMHETableSimplifiedDataModel"
              frameworkComponents={{
                nameWithToolTip: NameWithToolTip(),
                acWithToolTip: GroupWithToolTip(),
                groupFilter: GroupFilter,
              }}
              key={`${aproxHeight}-${granularity}-${result.byHour}-${props.editing}-${colDefs?.length}`}
              messages={messages}
              showCOG={false}
              pagination={false}
              filter
              columnDefs={(colDefs && colDefs) || []}
              rowData={newRowData}
              suppressMovableColumns
              stopEditingWhenGridLosesFocus
              getRowClass={getRowClass}
              onGridReady={onGridReady}
              pinnedBottomRowData={pinnedBottomRowData}
              onFilterChanged={onFilterChanged}
              onSortChanged={onSortChanged}
              // Grouping
              suppressAggFuncInHeader
              groupDefaultExpanded={-1}
              showCollapseButtonsInsideTable
              autoGroupColumnDef={{
                width: result.byHour ? 400 : 300,
                pinned: true,
                headerName: groupHeaderName,
                headerTooltip: groupHeaderName,
                menuTabs: ['filterMenuTab'],
                filter: 'groupFilter',
                field: result.byHour ? 'activity' : 'activity',
                cellRendererSelector: result.byHour
                  ? null
                  : params => {
                    if (params.node.rowPinned === 'bottom') {
                      return;
                    }
                    if (params.node.group)
                      return {
                        component: 'agGroupCellRenderer',
                        params: {
                          suppressCount: true,
                        },
                      };
                    return {
                      component: 'acWithToolTip',
                      params: {
                        padding: 2,
                      },
                    };
                  },

                // hack to make filterMenuTab visible for  autoColumn
                // valueGetter: params => '',
                filterValueGetter(params) { },
                filterParams: {
                  columns: filterColumns,
                  applyButton: true,
                  clearButton: true,
                },
                cellRenderer: result.byHour ? 'agGroupCellRenderer' : null,
                cellRendererParams: result.byHour ? { suppressCount: true } : null,
                pinnedRowCellRendererFramework: result.byHour ? TotalTitle() : null,
              }}
            />
            {(calculationStatus !== 'finished' || isLoading) && (
              <Loading status={isLoading ? 'loading' : calculationStatus} />
            )}
          </>
        )}
      </Wraptable>
    </Wrap>
  );
};

const mapStateToProps = createStructuredSelector({
  //result: makeSelectTableMHE(),
  isLoading: makeSelectRunningApiCalls(),
});

const withConnect = connect(mapStateToProps);

export default injectIntl(withConnect(ResultMatrixMHETableSimplifiedDataModelMasterPlan));
