/* eslint-disable no-restricted-syntax */
/**
 *
 * PlannedVolumeTable
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { selectEditFromplan, selectShowFormula, selectShowValue, selectShowFormulaVar, selectShowValueVar, selectPeriodIndexFromPlan } from 'containers/PlanDetailPage/selectors';
import { deletePlannedVolume, toggleVolumeValue, toggleVolumeFormula, toggleVolumeValueVar, toggleVolumeFormulaVar } from 'containers/PlanDetailPage/actions';
import { selectEditFromPa, selectShowFormulaPa, selectShowValuePa, selectShowFormulaPaVar, selectShowValuePaVar, selectPeriodIndexFromPa } from 'containers/PlanningAreaDetailPage/selectors';
import { deleteBudgetVolume, toggleVolumeValuePa, toggleVolumeFormulaPa, toggleVolumeValuePaVar, toggleVolumeFormulaPaVar } from 'containers/PlanningAreaDetailPage/actions';
import { selectEdit as getResultPageEdit, selectShowValueResult, selectShowFormulaResult } from 'containers/PlanResultPage/selectors';
import { selectUOMS } from 'containers/App/selectors';
import { TableWrap } from 'containers/PlanResultPage/styled';
import Button from 'components/Button';
import {textToFormulaActivity } from 'utils/formulas';

import { toNumber } from 'utils/utils';
import Table from 'containers/TableControlled';
import { TABLE_DEFAULTS } from 'containers/App/constants';
import { connect as formikConnect, getIn } from 'formik';

import cloneDeep from 'lodash/cloneDeep';
import { cellEditDirtyMarker } from '../DetailPageShared';
import { withDeleteDialog, DeleteDialog } from '../Dialog';

import messages from './messages';
import { createDeleteColumn, isDaily, createStandardVolumeColumnDefsMasterPlan } from './volume';
import { isEqual } from 'lodash';

const Wrap = styled.div`
  display: flex;
  height: ${props => props.height}px;
`;

const Title = styled.div`
  font-size: ${props => props.theme.fontSize.title};
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 10px;
`;

/* eslint-disable react/prefer-stateless-function */
class PlannedVolumeTable extends React.PureComponent {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.edit !== nextProps.edit) {
      return true;
    }
    // if(!isEqual(this.props.formik.values.planningParameters, nextProps.formik.values.planningParameters)){
    //   return true;
    // }
    this.props.formik.values?.planningParameters?.forEach((p, index)=>{
      if(!isEqual(p?.volumeCategoryParameters, nextProps.formik.values.planningParameters[index]?.volumeCategoryParameters)){
        return true;
      }
    })
    this.props.formik.initialValues?.planningParameters?.forEach((p, index)=>{
      const newHeaders = getIn(nextProps.formik.initialValues, `planningParameters[${index}].volumeCategoryParameters`);
      const currentHeaders = getIn(this.props.formik.initialValues, `planningParameters[${index}].volumeCategoryParameters`);
      if(!isEqual(newHeaders, currentHeaders)){
        return true;
      }
    })
   
    return  this.isDaily(nextProps) !== this.isDaily();
  }

  isDaily = extProps => {
    const props = extProps || this.props;
    if (props.daily !== undefined) {
      return props.daily;
    }

    return isDaily(props);
  };

  getDataKey() {
    return (this.isDaily() && 'dailyRowData') || 'rowData';
  }

  createColumnDefs(props, categoryMessage, index) {
    const columnDefs = createStandardVolumeColumnDefsMasterPlan(
      props,
      messages,
      categoryMessage,
      'volumeCategoryParameters',
      this.isDaily(),
      index
    );
    if (props.edit && props.deleteLine) {
      const onDelete = payload => {
        props.openDeleteDialog(props.deleteLine, payload, {
          catName: payload.volumeCategory.name,
          uomName: payload.uom.name,
        });
      };
      columnDefs.unshift(createDeleteColumn({ props, messages, onDelete }));
    }
    return columnDefs;
  }

  render() {
    let rowData = [];
    let rowDataVar = [];
    return (
      <>
        {
          this.props?.formik?.values?.planningParameters?.map((data, index) => {
            const dataKey = this.getDataKey();
            console.log(data)
            rowData = cloneDeep(data.volumeCategoryParameters?.[dataKey]);
            if (this.props.resultPage && (rowData)) {
              rowData = [...rowData];
              return( 
              <div key={index}>
              {rowData?.length > 0 && this.renderTable(rowData, messages.category, messages.categorySection, '', index, data)}
              </div>)
            }
          })
        }
        <DeleteDialog {...this.props} text={messages.dialogDeleteText} />
      </>
    );
  }
  
  renderTable(rowData, categoryMessage, sectionMessage, varPostfix, index, data) {
    const aproxHeight = rowData.length * 35 + 90; // TODO: something better
    return (
      <div>
        <Title>{`Volume Category for ${data.planName}`}</Title>
        <Wrap height={aproxHeight}>
          <Table
            key={index}
            name={
              this.props.resultPage
                ? this.props.edit
                  ? 'plannedVolumeTableEdit'
                  : 'plannedVolumeTable'
                : this.props.edit
                ? 'budgetVolumeTableEdit'
                : 'budgetVolumeTable'
            }
            messages={messages}
            defaultConfig={
              this.props.edit
                ? TABLE_DEFAULTS.plannedVolumeTableEditConfigConfig
                : TABLE_DEFAULTS.plannedVolumeTableConfig
            }
            pagination={false}
            columnDefs={this.createColumnDefs(this.props, categoryMessage, index)}
            rowData={rowData}
            onCellValueChanged={params => this.onCellValueChanged(params, varPostfix, index)}
            getRowNodeId={data =>  data.volumeCategory.id}
            deltaRowDataMode = {true}
            onPaste={data => this.onPaste(data, varPostfix)}
            animateRows={false}
            showCOG={false}
            singleClickEdit
            isMasterPlanResult={true}
            {...cellEditDirtyMarker(this.props.formik.setFieldValue)}
          />
        </Wrap>
      </div>
    );
  }

  onCellValueChanged = (params, varPostfix, index) => {
    const { formik } = this.props;
    const fieldName = `planningParameters[${index}].volumeCategoryParameters.${this.getDataKey()}${varPostfix}.${
      params.data.index
    }.${params.colDef.field}`;
    if((params.newValue == params.oldValue) || ((params.oldValue === undefined) && (params.newValue === ""))){
      return false;
    }
    const containsFormula = (fieldName && fieldName.includes('_formula')) || (fieldName && fieldName.includes('defaultFormula')) ;
    const formula = containsFormula && textToFormulaActivity(params.newValue.trim());
    const formulaValue = params.newValue ? JSON.stringify({ r: formula }) : null;
    formik.setFieldValue(fieldName, containsFormula? params.newValue.trim() : toNumber(params.newValue));
  };

  onPaste = (data, varPostfix) => {
    const { formik } = this.props;
    const fieldName = `planningParameters.volumeCategoryParameters.${this.getDataKey()}${varPostfix}`;
    formik.setFieldValue(fieldName, data);
  };
}

PlannedVolumeTable.propTypes = {
  edit: PropTypes.bool,
  plan: PropTypes.bool,
  formik: PropTypes.object,
  deleteLine: PropTypes.func,
  daily: PropTypes.bool,
  periodIndex: PropTypes.number,
};

// PLAN
const mapPlanStateToProps = createStructuredSelector({
  edit: selectEditFromplan,
  uoms: selectUOMS,
  showValue: selectShowValue,
  showFormula: selectShowFormula,
  showValueVar: selectShowValueVar,
  showFormulaVar: selectShowFormulaVar,
  periodIndex: selectPeriodIndexFromPlan,
});

function mapPlanDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteLine: deletePlannedVolume,
      toggleVolumeFormula: toggleVolumeFormula,
      toggleVolumeValue: toggleVolumeValue,
      toggleVolumeFormulaVar: toggleVolumeFormulaVar,
      toggleVolumeValueVar: toggleVolumeValueVar,
    },
    dispatch,
  );
}

const withPlanConnect = connect(
  mapPlanStateToProps,
  mapPlanDispatchToProps,
);

// PA
const mapPaStateToProps = createStructuredSelector({
  edit: selectEditFromPa,
  uoms: selectUOMS,
  showValue: selectShowValuePa,
  showFormula: selectShowFormulaPa,
  showValueVar: selectShowValuePaVar,
  showFormulaVar: selectShowFormulaPaVar,
  periodIndex: selectPeriodIndexFromPa,
});

function mapPaDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteLine: deleteBudgetVolume,
      toggleVolumeFormula: toggleVolumeFormulaPa,
      toggleVolumeValue: toggleVolumeValuePa,
      toggleVolumeFormulaVar: toggleVolumeFormulaPaVar,
      toggleVolumeValueVar: toggleVolumeValuePaVar,
    },
    dispatch,
  );
}

const withPaConnect = connect(
  mapPaStateToProps,
  mapPaDispatchToProps,
);

export default compose(
  injectIntl,
  withPlanConnect,
  withDeleteDialog,
  formikConnect,
)(PlannedVolumeTable);

export const BudgetVolumeTable = compose(
  injectIntl,
  withPaConnect,
  withDeleteDialog,
  formikConnect,
)(PlannedVolumeTable);

const mapResultStateToProps = createStructuredSelector({
  edit: getResultPageEdit,
  showValue: selectShowValueResult,
  showFormula: selectShowFormulaResult
});

const withResultConnect = connect(mapResultStateToProps);

export const ResultMasterPlanVolumeTable = compose(
  injectIntl,
  withResultConnect,
  withDeleteDialog,
  formikConnect,
)(props => (
  <TableWrap {...props}>
    {' '}
    <PlannedVolumeTable daily={false} {...props} />{' '}
  </TableWrap>
));

export const ResultMasterPlanVolumeTableDaily = props => <ResultMasterPlanVolumeTable {...props} daily />;
