/*
 * MasterPlanDetailPage Messages
 *
 * This contains all the text for the MasterPlanDetailPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.MasterPlanDetailPage.header',
    defaultMessage: 'Master Plan Detail - {name}',
  },
  headerOnly: {
    id: 'app.containers.MasterPlanDetailPage.headerOnly',
    defaultMessage: 'Master Plan Detail - ',
  },
  newPlan: {
    id: 'app.containers.MasterPlanDetailPage.newPlan',
    defaultMessage: 'New Master Plan',
  },
  basicInfo: {
    id: 'app.containers.MasterPlanDetailPage.basicInfo',
    defaultMessage: 'Basic Information',
  },
  plan: {
    id: 'app.containers.MasterPlanDetailPage.plan',
    defaultMessage: 'Plan',
  },
  addPlanAssociation: {
    id: 'app.containers.MasterPlanDetailPage.addPlanAssociation',
    defaultMessage: 'Add',
  },
  validGaps: {
    id: 'app.containers.MasterPlanDetailPage.validGaps',
    defaultMessage: '*Plan valid from must be directly after valid to of previous plan for',
  },
  plans: {
    id: 'app.containers.MasterPlanDetailPage.plans',
    defaultMessage: 'Plans',
  },
  automaticRefreshForReporting: {
    id: 'app.containers.MasterPlanDetailPage.automaticRefreshForReporting',
    defaultMessage: 'Automatic Refresh for Reporting',
  },
  customers: {
    id: 'app.containers.MasterPlanDetailPage.customers',
    defaultMessage: 'Customers',
  },
  changeAnyway: {
    id: 'app.containers.MasterPlanDetailPage.changeAnyway',
    defaultMessage: 'Change Anyway',
  },
  facilities: {
    id: 'app.containers.MasterPlanDetailPage.facilities',
    defaultMessage: 'Facilities',
  },
  facilityDeleteForbidden: {
    id: 'app.containers.MasterPlanDetailPage.facilityDeleteForbidden',
    defaultMessage: "Can't delete the facility, because it is has an associated plan",
  },
  customerDeleteForbidden: {
    id: 'app.containers.MasterPlanDetailPage.customerDeleteForbidden',
    defaultMessage: "Can't delete the customer, because it is has an associated plan",
  },
  managementUnitsDeleteForbidden: {
    id: 'app.containers.MasterPlanDetailPage.managementUnitsDeleteForbidden',
    defaultMessage: "Can't delete the management unit, because it is has an associated plan",
  },
  masterPlanConflict: {
    id: 'app.containers.MasterPlanDetailPage.masterPlanConflict',
    defaultMessage: 'Master Plan Conflict',
  },
  masterPlanConflictDescription: {
    id: 'app.containers.MasterPlanDetailPage.masterPlanConflictDescription',
    defaultMessage: 'Facility already has another master plan: {names}',
  },
  confirmDirty: {
    id: 'app.containers.MasterPlanDetailPage.confirmDirty',
    defaultMessage: 'There are unsaved changes at this master plan. Do you want to leave the page without saving them?',
  },
  planCopied: {
    id: 'app.containers.MasterPlanDetailPage.planCopied',
    defaultMessage: 'Master Plan Copied',
  },
  planSaved: {
    id: 'app.containers.MasterPlanDetailPage.planSaved',
    defaultMessage: 'Master Plan Saved',
  },
  planHasNoFacility: {
    id: 'app.containers.MasterPlanDetailPage.planHasNoFacility',
    defaultMessage: "Can't save master plan, because it has no facility",
  },
  planListName: {
    id: 'app.containers.MasterPlanDetailPage.planListName',
    defaultMessage: 'Name',
  },
  planListPlanningArea: {
    id: 'app.containers.MasterPlanDetailPage.planListPlanningArea',
    defaultMessage: 'Planning Area',
  },
  planListPlanningAreaBase:{
    id: 'app.containers.MasterPlanDetailPage.planListPlanningAreaBase',
    defaultMessage: 'Planning Area (baseline)',
  },
  planListFacility: {
    id: 'app.containers.MasterPlanDetailPage.planListFacility',
    defaultMessage: 'Facility',
  },
  planListManagementUnit: {
    id: 'app.containers.MasterPlanDetailPage.planListManagementUnit',
    defaultMessage: 'Management Unit',
  },
  planListCustomer: {
    id: 'app.containers.MasterPlanDetailPage.planListCustomer',
    defaultMessage: 'Customer',
  },
  planListValidFrom: {
    id: 'app.containers.MasterPlanDetailPage.planListValidFrom',
    defaultMessage: 'Valid From',
  },
  planListValidTo: {
    id: 'app.containers.MasterPlanDetailPage.planListValidTo',
    defaultMessage: 'Valid To',
  },
  planListModified: {
    id: 'app.containers.MasterPlanDetailPage.planListModified',
    defaultMessage: 'Modified',
  },
  planListModifiedBy: {
    id: 'app.containers.MasterPlanDetailPage.planListModifiedBy',
    defaultMessage: 'Modified by',
  },

  // runs
  runsTitle: {
    id: 'app.containers.MasterPlanDetailPage.runsTitle',
    defaultMessage: 'Reporting Runs',
  },
  requested: {
    id: 'app.containers.MasterPlanDetailPage.requested',
    defaultMessage: 'Requested',
  },
  type: {
    id: 'app.containers.MasterPlanDetailPage.type',
    defaultMessage: 'Type',
  },
  finished: {
    id: 'app.containers.MasterPlanDetailPage.finished',
    defaultMessage: 'Finished',
  },
  requestedBy: {
    id: 'app.containers.MasterPlanDetailPage.requestedBy',
    defaultMessage: 'Requested by',
  },
  status: {
    id: 'app.containers.MasterPlanDetailPage.status',
    defaultMessage: 'Status',
  },
  periodStartDay: {
    id: 'app.containers.MasterPlanDetailPage.periodStartDay',
    defaultMessage: 'Start Day',
  },
  periodEndDay: {
    id: 'app.containers.MasterPlanDetailPage.periodEndDay',
    defaultMessage: 'End Day',
  },
  reportingPeriod: {
    id: 'app.containers.MasterPlanDetailPage.reportingPeriod',
    defaultMessage: 'Reporting Period',
  },
  planUsed: {
    id: 'app.containers.MasterPlanDetailPage.planUsed',
    defaultMessage: 'Plan Used',
  },
  default: {
    id: 'app.containers.MasterPlanDetailPage.default',
    defaultMessage: 'Start Time',
  },
  smartCode: {
    id: 'app.containers.MasterPlanDetailPage.smartCode',
    defaultMessage: 'Smart Code',
  },
  warnings: {
    id: 'app.containers.MasterPlanDetailPage.warnings',
    defaultMessage: 'Warnings',
  },
  noWarningsAvailable: {
    id: 'app.containers.MasterPlanDetailPage.noWarningsAvailable',
    defaultMessage: 'No warnings available to display.',
  },
  reportingRunningError: {
    id: 'app.containers.MasterPlanDetailPage.reportingRunningError',
    defaultMessage: 'Latest Reporting run failed.',
  },
  warningsAvailable: {
    id: 'app.containers.MasterPlanDetailPage.warningsAvailable',
    defaultMessage: 'There are warnings available for this Master Plan!',
  },
  smartCodeStatusError: {
    id: 'app.containers.MasterPlanDetailPage.smartCodeStatusError',
    defaultMessage: 'Sync with SmartCode failed.',
  },
  activityKey: {
    id: 'app.containers.MasterPlanDetailPage.activityKey',
    defaultMessage: 'Activity',
  },
  cancelButton: {
    id: 'app.containers.MasterPlanDetailPage.cancelButton',
    defaultMessage: 'Cancel',
  },
  confirmButton: {
    id: 'app.containers.MasterPlanDetailPage.confirmButton',
    defaultMessage: 'Confirm',
  },
  activityCheckHeader: {
    id: 'app.containers.MasterPlanDetailPage.activityCheckHeader',
    defaultMessage: 'Master Plan Activity Check',
  },
  activityLoader:{
    id: 'app.containers.MasterPlanDetailPage.activityLoader',
    defaultMessage: 'Please wait while we load Activity check data',
  },
  snowFlakeWarning: {
    id: 'app.containers.MasterPlanDetailPage.snowFlakeWarning',
    defaultMessage: 'Snowflake not available.',
  },
  mappingsOnly: {
    id: 'app.containers.MasterPlanDetailPage.mappingsOnly',
    defaultMessage: 'For Mapping only',
  },
  planValidityWarning:{
    id: 'app.containers.MasterPlanDetailPage.planValidityWarning',
    defaultMessage: 'Please check the validity of plan(s)!',
  },
  noPlansWarning:{
    id: 'app.containers.MasterPlanDetailPage.noPlansWarning',
    defaultMessage: 'No plan has assigned for this Masterplan. Please create a new plan or select from the existing plans.',
  },
  emptyHeader:{
    id: 'app.containers.MasterPlanDetailPage.emptyHeader',
    defaultMessage: ` `,
  },
  assignPlanWarning:{
    id: 'app.containers.MasterPlanDetailPage.assignPlanWarning',
    defaultMessage: 'Please assign or create a plan!',
  },
  createNewPlan:{
    id: 'app.containers.MasterPlanDetailPage.createNewPlan',
    defaultMessage: 'Create New Plan',
  },
  managementUnits:{
    id: 'app.containers.MasterPlanDetailPage.managementUnits',
    defaultMessage: 'Management Units',
  },
  managementUnitWarning:{
    id: 'app.containers.MasterPlanDetailPage.managementUnitWarning',
    defaultMessage: 'Management Unit is mandatory, please assign Management Unit to create a new plan!',
  },
  logs:{
    id: 'app.containers.MasterPlanDetailPage.logs',
    defaultMessage: 'Logs',
  },
  oldValue:{
    id: 'app.containers.MasterPlanDetailPage.oldValue',
    defaultMessage: 'Old Value',
  },
  newValue:{
    id: 'app.containers.MasterPlanDetailPage.newValue',
    defaultMessage: 'New Value',
  },
  user:{
    id: 'app.containers.MasterPlanDetailPage.user',
    defaultMessage: 'User',
  },
  lastValidityFrom:{
    id: 'app.containers.MasterPlanDetailPage.lastValidityFrom',
    defaultMessage: 'Last Validity From',
  },
  newValidityFrom:{
    id: 'app.containers.MasterPlanDetailPage.newValidityFrom',
    defaultMessage: 'New Validity From',
  },
  omsChangeHistory:{
    id: 'app.containers.MasterPlanDetailPage.omsChangeHistory',
    defaultMessage: 'OMS Code change warnings',
  }
});
