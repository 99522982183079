import React from 'react';

import { LOCAL_STORAGE_KEYS, PATHS, TABLE_DEFAULTS } from 'containers/App/constants';
import { PERMISSIONS } from 'utils/security';
import { parseSearch, dataToSearch } from 'utils/url';
import { dateComparator, DateNoTimeCell } from 'components/ListGrid/utils';

import makeListPage from '../ListPage';
import messages from './messages';

const MasterPlanListPage = makeListPage({
  defaultTableConfig: TABLE_DEFAULTS.MASTER_PLAN_DEFAULT,
  tableConfigKey: LOCAL_STORAGE_KEYS.MASTER_LIST_TABLE_CONFIG,
  permissionAdd: PERMISSIONS.VIEW_MASTER_PLAN,
  permissionDisable: PERMISSIONS.VIEW_MASTER_PLAN,
  permissionSaveConfig: PERMISSIONS.SAVE_TABLE_CONFIG,
  permissionDisableData: PERMISSIONS.DISABLE_PLAN_IGNORE_RESTRICTIONS,
  permissionViewDetail: PERMISSIONS.VIEW_MASTER_PLAN,
  pathToDetail: PATHS.masterPlanDetail,
  pathToCreate: PATHS.makeMasterPlan,
  pathToSelf: PATHS.masterPlanList,
  messages,
  domainName: 'masterPlanListPage',
  domainUrl: 'masterPlan',
  exportToExcel: true,
  parseData: item => ({
    id: item.id,
    name: item.name,
    facilities: item.facilities || [],
    customers: item.customers || [],
    managementUnits: item.managementUnits || [],
    created: item.createdDate,
    createdBy: item.createdBy,
    updated: item.updatedDate,
    updatedBy: item.updatedBy,
    editable: true,
    deleted: item.deleted,
    validFrom: item.plans.length > 1 ? new Date(Math.min(...item.plans.map(e => new Date(e.validFrom)))).toLocaleDateString() : item.plans.length ==1 ? item.plans[0].validFrom :'',
    validTo: item.plans.length > 1 ? new Date(Math.max(...item.plans.map(e => new Date(e.validTo)))).toLocaleDateString() : item.plans.length ==1 ? item.plans[0].validTo :'',
    pageName: 'Master Plan',
    hasWarning: item.hasWarning,
  }),
  interceptColDef: (colDefs, config, props) =>{
    const validFrom =   {
      headerName: props.intl.formatMessage(messages.validFrom),
      field: 'validFrom',
      colId: 'validFrom',
      menuTabs: ['filterMenuTab'],
      width: 120,
      cellRendererFramework: DateNoTimeCell,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
    };
    const validTo = {
      headerName: props.intl.formatMessage(messages.validTo),
      field: 'validTo',
      colId: 'validTo',
      menuTabs: ['filterMenuTab'],
      width: 120,
      cellRendererFramework: DateNoTimeCell,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['lessThan', 'greaterThan', 'inRange'],
        comparator: dateComparator,
      },
    };
    const warning = {
      colId: 'warning',
      field: 'warning',
      headerName: props.intl.formatMessage(messages.warning),
      cellRendererFramework: params => {
        return params?.data?.hasWarning ? <div style={{ color: 'red', fontWeight: 'bold', fontSize: 'x-large' }}>{`!`}</div> : null;
      },
      resizable: true,
      suppressMenu: true,
      width: 80,
    };
    colDefs.splice(5, 0, warning);
    colDefs.splice(6, 0, validFrom);
    colDefs.splice(7 , 0, validTo);
    // colDefs.push({
    //   headerName: props.intl.formatMessage(messages.calculated),
    //   field: 'calculated',
    //   colId: 'calculated',
    //   menuTabs: ['filterMenuTab'],
    //   cellRendererFramework: DateCell,
    //   valueGetter: params => params.data.calculated && parseDate(params.data.calculated),
    //   filter: 'agDateColumnFilter',
    //   filterParams: {
    //     filterOptions: ['lessThan', 'greaterThan', 'inRange'],
    //   },
    //   width: 150,
    // });
    // colDefs.push({
    //   headerName: props.intl.formatMessage(messages.calculatedBy),
    //   field: 'calculatedBy',
    //   colId: 'calculatedBy',
    //   menuTabs: ['filterMenuTab'],
    //   width: 150,
    // });
    // colDefs.push({
    //   headerName: props.intl.formatMessage(messages.numOfThisMonthCalcs),
    //   field: 'numOfThisMonthCalcs',
    //   colId: 'numOfThisMonthCalcs',
    //   menuTabs: ['filterMenuTab'],
    //   filter: 'agNumberColumnFilter',
    //   width: 200,
    // });
    // colDefs.push({
    //   headerName: props.intl.formatMessage(messages.numOfLastMonthCalcs),
    //   field: 'numOfLastMonthCalcs',
    //   colId: 'numOfLastMonthCalcs',
    //   menuTabs: ['filterMenuTab'],
    //   filter: 'agNumberColumnFilter',
    //   width: 200,
    // });
    return colDefs;
  },
  onGridReady: (params, props) => {
    const pa = parseSearch('filterForPA', props);
    if (pa) {
      const filterModel = params.api.getFilterModel();
      if (pa) filterModel.area = { values: [pa], filterType: 'set', search: pa };
      setTimeout(() => params.api.setFilterModel({ ...filterModel }), 1000);
    }
  },

  actionCellRendererParams: (props, config) => ({
    onDetail:
      props.hasPerm(config.permissionViewDetail) &&
      (id => {
        props.history.push(config.pathToDetail.replace(':id', id));
      }),
      onResult: id => {
        const defaultOptions = {
          masterPlanId: id,
        };
        props.history.push({ pathname: PATHS.masterplanResult, search: dataToSearch(defaultOptions) });
      },
    // onCopy:
    //   props.hasPerm(PERMISSIONS.COPY_PLAN) &&
    //   (id => {
    //     props.history.push(PATHS.makeCopyPlan.replace(':id', id));
    //   }),
  }),
});

export default MasterPlanListPage;
