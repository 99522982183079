import React, { useContext, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { connect as formikConnect, FastField, Field, FormikProps, setIn } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled, { Theme, ThemeContext, withTheme } from 'styled-components';

import Button from 'components/Button';
import { ComponentWithDirtyCheck } from 'components/ButtonWithDirtyCheck';
import { useStyles } from 'components/FormikDayPicker/styled';
import StyledLabel from 'components/Label';
import Select from 'components/StyledSelect';
import { getToken } from 'containers/App/selectors';
import { smartProdRunsCopyRunSettings, smartProdRunsRequest, smartProdRunsDeleteSchedule, saveSmartProdRuns, saveTimeTransformationRuns } from 'containers/ForecastDetailPage/fetch';
import { formatDate, formatDateTime, formatTime } from 'utils/dateTime';
import { ApiSmartProdRunDTO, ApiSmartProdRunScheduleDTO, ApiSmartProdRunStatus } from 'types/drep-backend.d';

import CustomScheduleDialog, { SchedulingDTO } from '../PlanInterfaces/CustomScheduleDialog';
import messages from './messages';
import RelativePeriod from './RelativePeriod';
import TimeTransformationRunTableDateRangePicker from './TimeTransformationRunTableDateRangePicker';
import ShiftMappings from './ShiftMappings';
import StartEndDate from './StartEndDate';
import { EntityEntry, ForecastForm, SmartProdPeriodTypeEnum, SmartProdRunEnum } from './types';

const SectionWithTitle = styled(props => <Box p={2} pt={1} {...props}></Box>)`
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 8px;

  h6 {
    margin-top: 4px;
    margin-bottom: 16px;
  }
`;

const SectionWithTitleSM = styled(props => <Box p={2} pt={1} {...props}></Box>)`
  background-color: rgba(0, 0, 0, 0.05);
`;

const CompactButton = styled(Button)`
  margin-bottom: 12px;
`;
const StyledButton = styled(Button)`
  display: inline-block;
  width: auto;
  height: auto;
  margin-right: 12px;
`;

const DetailWrapper = styled.div`
  max-width: 1440px;
  padding: 20px;
  z-index: 100;

  ${CompactButton} {
    white-space: normal;
    max-width: 120px;
    height: auto;
  }

  div > span {
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`;

const CenteredLabel = styled(StyledLabel)`
  height: 100%;
  display: table;

  > span {
    display: table-cell;
    vertical-align: middle;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 28px;
`;

const InputError = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${props => props.theme.color.red};
`;

const CheckboxInput = styled.input`
  float: left;
  margin-right: 12px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 5px;
`;

const StyledLabelForecast = styled(StyledLabel)`
  color : red;
  margin-left: 20px;
`

const Hr = styled.hr`
  border: none;
  margin-bottom: 20px;
`;

type CommonRunDTO = ApiSmartProdRunDTO | ApiSmartProdRunScheduleDTO;

type Props = {
  intl: InjectedIntl;
  theme: Theme;
  formik: FormikProps<ForecastForm>;
  data: CommonRunDTO;
  forecastEdit: boolean;
  allOmsCodes: EntityEntry[];
  allSmartProdSources: EntityEntry[];
  planningParametersId: number;
  handleSubmitPage: Function;
  setOpenedDetail: (detail: number) => void;
  handleReloadData: () => void;
  runType: string;
  hasAdjustments: boolean;
  planDetails
};

const NonEditableStatuses: ApiSmartProdRunStatus[] = ['SUCCESSFUL', 'FAILED', 'RUNNING'];

const isEditableStatus = (data: CommonRunDTO) => {
  if (!data) {
    return false;
  }
  if ('status' in data && NonEditableStatuses.includes(data.status)) {
    return false;
  }
  return true;
};

// export const validateTargets = (data: CommonRunDTO, intl: InjectedIntl, useEventNameInError = false) => {
//   if (!isEditableStatus(data)) {
//     return false;
//   }
//   if (useEventNameInError) {
//     return intl.formatMessage(messages.smartProdRunsErrorSmartProdRunName, {
//       smartProdRun: data.name,
//       error: intl.formatMessage(messages.smartProdRunsValidateSmartProdRunEntry),
//     });
//   }
//   return intl.formatMessage(messages.smartProdRunsValidateSmartProdRunEntry);
// };

const validateOMSCodes = (data: CommonRunDTO, intl: InjectedIntl, useEventNameInError = false) => {
  if (!isEditableStatus(data)) {
    return false;
  }
  if (!data.omses || !data.omses.length) {
    if (useEventNameInError) {
      return intl.formatMessage(messages.smartProdRunsErrorSmartProdRunName, {
        smartProdRun: data.name,
        error: intl.formatMessage(messages.eventsTableSelectedOMSCodeValidation),
      });
    }
    return intl.formatMessage(messages.eventsTableSelectedOMSCodeValidation);
  }
  return undefined;
};

export const validateSmartProdRunEntry = (data: CommonRunDTO, intl: InjectedIntl, useEventNameInError = false) => {
  let errors = {};
  if (validateOMSCodes(data, intl)) {
    errors = { ...errors, omses: validateOMSCodes(data, intl, useEventNameInError) };
  }
  // if (validateTargets(data, intl)) {
  //   errors = { ...errors, targets: validateTargets(data, intl, useEventNameInError) };
  // }
  return errors;
};

const TimeTransformationTableDetailCellRenderer: React.FC<Props> = ({
  intl,
  theme,
  formik,
  data,
  forecastEdit,
  allOmsCodes,
  allSmartProdSources,
  planningParametersId,
  handleSubmitPage,
  setOpenedDetail,
  handleReloadData,
  runType,
  hasAdjustments,
  planDetails
}) => {
  const token: string = useSelector(getToken);
  const [schedulingDialogData, setSchedulingDialogData] = useState<SchedulingDTO>();
  const dispatch = useDispatch();
  const themeContext: { color: { yellow: string } } = useContext(ThemeContext);
  const classes = useStyles(theme, 48)();
  const editableStatus = isEditableStatus(data);
  const editable = forecastEdit && editableStatus;
  const renderField = (
    labelName,
    fieldName,
    input,
    fastField = true,
    labelWidth = undefined,
    inputWidth = undefined,
    validate = undefined,
  ) => (
    <>
      {labelName && (
        <Grid item xs={labelWidth || 4}>
          <CenteredLabel {...labelName} />
        </Grid>
      )}
      <Grid item xs={inputWidth || 8}>
        {fastField ? (
          <FastField name={fieldName} render={input} validate={validate} />
        ) : (
          <Field name={fieldName} render={input} validate={validate} />
        )}
      </Grid>
    </>
  );

  const isRequested = runType === SmartProdRunEnum.REQUEST;
  const isScheduled = runType === SmartProdRunEnum.SCHEDULE;
  const runsField = 'timeTransformationRuns';

  const handleAddAllTabCodes = () => {
    formik.setFieldValue(
      `${runsField}[${data.id}].periods`,
      tabDetails.map(code => (code.value )),
    );
  };

  const handleAddAllOMSCodes = () => {
    formik.setFieldValue(
      `${runsField}[${data.id}].omses`,
      allOmsCodes.map(code => ({ id: code.value })),
    );
  };

  const handleRemoveAllOMSCodes = () => {
    formik.setFieldValue(`${runsField}[${data.id}].omses`, []);
  };

  const handleSaveRequest = async ({ waitForSaveAll }) => {
    if (waitForSaveAll) {
      await handleSubmitPage(formik.values);
    }
    const changedData = Object.values(formik.values.timeTransformationRuns).filter(run => run.id === data.id);
    changedData.forEach((c)=>{
      delete c.forecastDates;
      //@ts-ignore
      c.smartProdRunWzps.forEach((s)=>{
        s.id = s.id.length > 15 ? null : s.id
      })
    });
    token && saveTimeTransformationRuns(
      token,
      dispatch,
      planningParametersId,
      changedData
    ).then(response => {
      if (response.isOk) {
        handleReloadData();
      }
    })
  };

  const handleDeleteSchedule = (data) => {
    token &&
      smartProdRunsDeleteSchedule(token, dispatch, data.id).then(response => {
        if (response.isOk) {
          setOpenedDetail(null);
          handleReloadData();
        }
      });
  };

  const handleRequest = async ({ waitForSaveAll }) => {
    if (waitForSaveAll) {
      await handleSubmitPage(formik.values);
    }
    token &&
      smartProdRunsRequest(token, dispatch, planningParametersId, data.id).then(response => {
        if (response.isOk) {
          handleReloadData();
        }
      });
  };

    const ttData = Object.values(formik.values?.timeTransformationRuns)?.filter((r)=> r.id === data.id);
    const modifiedttData = Object.values(formik.initialValues?.timeTransformationRuns)?.filter((r)=> r.id === data.id);
    //@ts-ignore
    const disableflag = isEqual(ttData[0].periods, modifiedttData[0]?.periods);
  const handleCopyRunSettings = async ({ waitForSaveAll }) => {
    if (waitForSaveAll) {
      await handleSubmitPage(formik.values);
    }
    const runArray = formik.values.timeTransformationRuns;
    token &&
      smartProdRunsCopyRunSettings(token, isRequested ? 'smartProdRun' : 'smartProdRun/schedule', dispatch, data.id, {
        ...(data as ApiSmartProdRunDTO),
        name: `Copy of ${waitForSaveAll ? runArray[data.id].name : data.name}`,
      }).then(response => {
        if (response.isOk) {
          setOpenedDetail(response.data.id);
          handleReloadData();
        }
      });
  };

  const requestButton = () => (
    <ComponentWithDirtyCheck
      actionPayload={{}}
      actionHandler={handleRequest}
      formikOverride={{ submitForm: () => { }, ...formik }}
    >
      {({ onClickHandler }) => (
        <StyledButton
          onClick={onClickHandler}
          color="secondary"
          disabled={ //@ts-ignore
            !editableStatus || !isEmpty(validateSmartProdRunEntry(formik.values.smartProdRuns[data.id], intl)) || data?.smartProdRunWzps?.every((r) => !r.wzpName) || !(data?.periods?.length > 0)
          }
        >
          <FormattedMessage {...messages.smartProdRunsRequestButton} />
        </StyledButton>
      )}
    </ComponentWithDirtyCheck>
  )

  const runArray = formik.values.timeTransformationRuns;

  const periodRenderFunction = ({ titleLabel, dataType, periodType }) => {
    const dateLimits =
      dataType === 'dataSourceDates' ? { maxDate: DateTime.now().minus({ days: 1 }) } : { minDate: DateTime.now() };
    return (
      <SectionWithTitle>
        <h6>
          <StyledLabel {...titleLabel} />
        </h6>
        <Grid key={dataType} container spacing={2}>
          <StartEndDate
            startFieldName={`timeTransformationRuns[${data.id}].${dataType}.startDate`}
            endFieldName={`timeTransformationRuns[${data.id}].${dataType}.endDate`}
            forecastEdit={forecastEdit}
            startDateLimits={forecastEdit ? dateLimits : undefined}
            endDateLimits={forecastEdit ? dateLimits : undefined}
            formik={formik}
            data={data}
          />
          <TimeTransformationRunTableDateRangePicker
            intl={intl}
            dataType={dataType}
            forecastEdit={editable}
            data={data}
          />
        </Grid>
      </SectionWithTitle>
    );
  };
  
  const tabDetails = planDetails?.planningParameters?.periods?.map((p, index)=>( {value: p.id, label: index === 0 ? 'Default' :  `${formatDate(p.startDay)} - ${formatDate(p.endDay)}`}));
  return (
    <DetailWrapper>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <span>{requestButton()}</span>
          {forecastEdit && (
            <span>
              <ComponentWithDirtyCheck
                actionPayload={{}}
                actionHandler={handleCopyRunSettings}
                formikOverride={{ submitForm: () => { }, ...formik }}
              >
                {({ onClickHandler }) => (
                  <StyledButton
                    onClick={onClickHandler}
                    color="secondary"
                    disabled={
                      !isEmpty(
                        validateSmartProdRunEntry(formik.values.timeTransformationRuns[data.id],
                          intl,
                        ),
                      )
                    }
                  >
                    <FormattedMessage {...messages.smartProdRunsCopyRunSettingsButton} />
                  </StyledButton>
                )}
              </ComponentWithDirtyCheck>
            </span>
          )}
        </Grid>
        <Grid item xs={10} />
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {renderField(
              messages.smartProdRunsName,
              `${runsField}[${data.id}].name`,
              ({ field, form }) => (
                <StyledInput
                  disabled={!editable}
                  name={field.name}
                  type="text"
                  value={field.value || ''}
                  onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
                />
              ),
              true,
              2,
              4,
            )}
            <Grid item xs={6} />
            {renderField(
              messages.smartProdRunsSmartProd,
              `${runsField}[${data.id}].smartProdSourceId`,
              ({ field, form }) => (
                <StyledSelect
                  options={allSmartProdSources}
                  isDisabled={!editable}
                  onChange={option => {
                    form.setFieldValue(field.name, option.value);
                  }}
                  value={allSmartProdSources.find(o => o.value === field.value) || null}
                />
              ),
              true,
              2,
              4,
            )}
            <Grid item xs={6} />
          </Grid>
          {
            [
              {
                titleLabel: messages.smartProdRunsDataSourcePeriod,
                dataType: 'dataSourceDates' as 'dataSourceDates' | 'forecastDates',
                periodType: SmartProdPeriodTypeEnum.SOURCE,
              },
            ].map(periodRenderFunction)
          }
          <SectionWithTitle>
            <h6 style={{display: 'flex'}}>
              <StyledLabel {...messages.smartProdRunsForecastPeriod} />
              { data?.periods?.length === 0 && <StyledLabelForecast {...messages.forecastInformationMessage} />}
            </h6>
            <Grid container spacing={1}>
              {renderField(
                messages.applyToTabs,
                `${runsField}[${data.id}].periods`,
                ({ field, form }) => {
                  return editable ? (
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        <Select
                          name={field.name}
                          isMulti
                          onChange={options => {
                            form.setFieldValue(field.name, options ? options.map(option => (option.value)) : []);
                          }}
                          editable={editable}
                          isDisabled={!editable}
                          value={tabDetails?.filter(option => field.value?.find(f => f === option.value))}
                          options={tabDetails}
                          isClearable={false}
                          closeMenuOnSelect={false}
                          styles={{
                            menuList: base => ({
                              ...base,
                              maxHeight: 150,
                            }),
                            multiValue: base => ({
                              ...base,
                              color: 'black',
                              backgroundColor: themeContext?.color?.yellow,
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CompactButton
                          onClick={handleAddAllTabCodes}
                          color="primary"
                          autoFocus
                          variant="contained"
                          disabled={!editable}
                        >
                          <FormattedMessage {...messages.eventsTableAddAllTabs} />
                        </CompactButton>
                        <CompactButton
                          onClick={handleSaveRequest}
                          color="primary"
                          autoFocus
                          variant="contained"
                          disabled={disableflag}
                        >
                          <FormattedMessage {...messages.confirmSelection} />
                        </CompactButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <div style={{ whiteSpace: 'normal' }}>
                      {tabDetails
                        .filter(option => field.value?.find(f => f === option.value))
                        .map(item => (
                          <span className={classes.values} key={item.value}>
                            {item.label}
                          </span>
                        ))}
                    </div>
                  );
                },
                true,
                12,
                12,
              )}
            </Grid>
          </SectionWithTitle>
          <SectionWithTitle>
            <Grid container spacing={1}>
              {renderField(
                messages.smartProdRunsOMSCodes,
                `${runsField}[${data.id}].omses`,
                ({ field, form }) => {
                  const omsValMessage = validateOMSCodes(
                    form.values.timeTransformationRuns[data.id],
                    intl,
                  );
                  return editable ? (
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        <Select
                          name={field.name}
                          isMulti
                          onChange={options => {
                            form.setFieldValue(field.name, options ? options.map(option => ({ id: option.value })) : []);
                          }}
                          editable={editable}
                          isDisabled={!editable}
                          value={allOmsCodes?.filter(option => field.value?.find(f => f.id === option.value))}
                          options={allOmsCodes}
                          isClearable={false}
                          closeMenuOnSelect={false}
                          styles={{
                            menuList: base => ({
                              ...base,
                              maxHeight: 150,
                            }),
                            multiValue: base => ({
                              ...base,
                              color: 'black',
                              backgroundColor: themeContext?.color?.yellow,
                            }),
                          }}
                        />
                        {omsValMessage && (
                          <InputError>
                            {validateOMSCodes(form.values.timeTransformationRuns[data.id], intl,
                            )}
                          </InputError>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <CompactButton
                          onClick={handleAddAllOMSCodes}
                          color="primary"
                          autoFocus
                          variant="contained"
                          disabled={!editable}
                        >
                          <FormattedMessage {...messages.eventsTableAddAllOMSCodes} />
                        </CompactButton>
                        <CompactButton
                          onClick={handleRemoveAllOMSCodes}
                          color="primary"
                          autoFocus
                          variant="contained"
                          disabled={!editable}
                        >
                          <FormattedMessage {...messages.eventsTableRemoveAllOMSCodes} />
                        </CompactButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <div style={{ whiteSpace: 'normal' }}>
                      {allOmsCodes
                        .filter(option => field.value?.find(f => f.id === option.value))
                        .map(item => (
                          <span className={classes.values} key={item.value}>
                            {item.label}
                          </span>
                        ))}
                    </div>
                  );
                },
                true,
                12,
                12,
              )}
            </Grid>
          </SectionWithTitle>
       
        </Grid>
        <Grid style={{ paddingTop: '77px' }} item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <SectionWithTitle>
                <h6>
                  <StyledLabel {...messages.dataToRequestTitle} />
                </h6>
                <Grid container spacing={1}>
                  {[
                    {
                      label: messages.matchDayOfWeek,
                      fieldName: `${runsField}[${data.id}].matchDayOfWeek`,
                    },
                  ].map(({ label, fieldName }, index) => (
                    <React.Fragment key={index}>
                      {renderField(
                        null,
                        fieldName,
                        ({ field, form }) => (
                          <span>
                            <CheckboxInput
                              id={fieldName}
                              disabled={!editable}
                              type="checkbox"
                              onChange={({ target }) => {
                                form.setFieldValue(fieldName, target.checked);
                              }}
                              checked={field.value}
                            />
                            <StyledLabel onClick={() => form.setFieldValue(fieldName, !field.value)} {...label} />
                          </span>
                        ),
                        true,
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
              </SectionWithTitle>
            </Grid>
          </Grid>
          { data?.periods?.length > 0 &&
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <SectionWithTitleSM style={{ width: '800px' }}>
                {
                  renderField(
                    messages.shiftMapping,
                    `${runsField}[${data.id}].shiftMapping`,
                    ({ field, form }) => {
                      const updateData = (params) => {
                        let newValues = { ...formik.values };
                        newValues = setIn(newValues, `timeTransformationRuns.${data.id}.shiftMapping.${params.rowIndex}.${params.colDef.field}`, Number(params.newValue));
                        formik.setValues(newValues);
                      }
                      return (
                        <ShiftMappings formik={form} intl={intl} editable={editable} id={data.id} updateData={updateData} planDetails={planDetails} handleSubmitPage={handleSubmitPage}/>
                      )
                    },
                    true,
                    12,
                    12
                  )
                }
              </SectionWithTitleSM>
            </Grid>
          </Grid>
          }
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </DetailWrapper>
  );
};

export default injectIntl(formikConnect(withTheme(TimeTransformationTableDetailCellRenderer)));
