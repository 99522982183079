// @flow

import sha256 from 'crypto-js/sha256';
import { call } from 'redux-saga/effects';

import { USER_VIEWS } from 'containers/App/constants';
import { loadFromLocalstorage, saveToLocalstorage } from 'containers/App/saga';
import { api, convertEntityWithPlanningParametersFromApi, formatDateToApiFormat, withUrl } from 'utils/api';
import { recalculateVolumeCategoryParameters, recalculateVolumeCategoryParametersMasterPlan } from 'utils/api/calculations';
import calendarMessages from 'utils/calendar/messages';
import { diffPlanningParameters } from 'utils/commonDetailSaga';
import { fetchData } from 'utils/reduxApi';
import { userLocale } from 'utils/utils';
import { cleanPool, getWorker } from 'utils/webworker/workerAPI';
import { DateTime } from 'luxon';

import {
  calculationStarted,
  storeCalcualtedTablesAction as storeResult,
  storeHeadsGraphData,
  storeHeadsGraphFilteredData,
  storePlanAction,
  storeResults,
  storeVolumeCP,
  storeVolumeRaw,
  storeResultVolumeRaw,
  storePlanBasicDetails
} from './actions';
import { mergeDataAndDefs } from './calculation/calculate';
import calculateLabourAvailabilityValuesExcel from './calculation/calculateLabourAvailableValuesExcel';
import { DT_TYPE, Filter, T_TYPE } from './calculation/types';
import { GRANULARITY } from './constants';
import messages from './messages';

export const defaultColumnSettings: Filter = {
  showVolume: true,
  showEffort: true,
  showFte: true,
  showHeads: true,
  showHeadsOpt: true,
  showProductivityRate: true,
  showAvailableHours: true,
  showAvailableFTE: true,
  showAvailableStaff: true,
  showlabourDiscrepancy: true,
  showVariance: true,
  showDirect: true,
  showIndirect: true,
  showBudget: false,
  showPlanned: true,
  showActuals: false,
  showForecast: false,
  showLabourAvailability: false,
  showNumberOfStaff: true,
  showEffortAdjustment: true,
};

export const defaultSettings = {
  ...defaultColumnSettings,
  startDate: null,
  endDate: null,
  granularity: GRANULARITY.WEEK,
  startDateActivity: null,
  planId: null,
  planningParametersId: null,
  granularityActivity: GRANULARITY.WEEK,
  effortFteTrend: 'effort',
  baselineTrend: true,
  plannedTrend: true,
  actualsTrend: false,
  effortFteActivity: 'effort',
  baselineActivity: true,
  plannedActivity: true,
  actualsActivity: false,
  availableTrend: false,
  includeMhe: false,
  includeRole: false,

  baselineDilo: true,
  plannedDilo: true,
  actualsDilo: false,
  effortFteDilo: 'effort',
  granularityDilo: GRANULARITY.WEEK,
  startDateDilo: null,
};

type FilterType = {
  startDate: Object, // luxon,
  endDate: Object, // luxon,
  granularity: string,
  planningParametersId: string,
  includeMhe: boolean,
  includeRole: Boolean,
  includeBaseLine: boolean,
  includeWeekStartAsSunday: Boolean,
  includeVolumeCategory: Boolean,
  includeForecast: boolean,
  plan?: Object,
};

function calculateRowsDifferenceAdjustmentByHour(params){
  let differenceValue;
  let sumEffort;
  let adjustmentEffort;
  if (params?.column?.colDef?.colId === "sum_planned_diffAdju") {
    sumEffort = params?.node?.aggData.sum_planned_e;
    adjustmentEffort = params?.node?.aggData.sum_planned_effAdju;
  } else if (params?.column?.colDef?.colId === "sum_budget_diffAdju") {
    sumEffort = params?.node?.aggData.sum_budget_e;
    adjustmentEffort = params?.node?.aggData.sum_budget_effAdju;
  } else if (params?.column?.colDef?.colId === "sum_forecast_diffAdju") {
    sumEffort = params?.node?.aggData.sum_forecast_e;
    adjustmentEffort = params?.node?.aggData.sum_forecast_effAdju;
  } 
  if (!sumEffort && !adjustmentEffort) {
    differenceValue = 0;
  }
  if (!sumEffort) {
    differenceValue = 100;
  }
  if (!adjustmentEffort) {
    differenceValue = 100;
  }
  if(sumEffort === 0 && adjustmentEffort ===0){
    differenceValue = 0;
  }
  if(sumEffort > 0 && adjustmentEffort === 0){
    differenceValue = -100;
  }
  if(sumEffort === 0 && adjustmentEffort > 0){
    differenceValue = 100;
  }
  if (sumEffort >0 && adjustmentEffort>0) {
    differenceValue = ((adjustmentEffort - sumEffort) / sumEffort) * 100;
  }
  return differenceValue ? differenceValue.toFixed(2) : 0;
}

function calculateRowsDifferenceAdjustment(params, dataSet = 'planned') {
  const result = [];
  if (params?.column?.colDef?.week) {
    result.push(`w${params?.column?.colDef?.week}`);
  }
  if (params?.column?.colDef?.day) {
    result.push(`d${params?.column?.colDef?.day}`);
  }
  if (params?.column?.colDef?.wzp && !params?.column?.colDef?.hour) {
    result.push(`wzp${params?.column?.colDef?.wzp}`);
  }
  if (params?.column?.colDef?.hour) {
    result.push(`h${params?.column?.colDef?.hour}`);
  }

  const string = result.join('_');
  const sumEffortColumn = `${string}_${dataSet}_e`;
  const effortAdjustmentColumn = `${string}_${dataSet}_effAdju`;
  let differenceValue;
  let sumEffort;
  let adjustmentEffort;
  if (params?.column?.colDef?.colId === "sum_planned_diffAdju") {
    sumEffort = params?.node?.aggData.sum_planned_e;
    adjustmentEffort = params?.node?.aggData.sum_planned_effAdju;
  } else if (params?.column?.colDef?.colId === "sum_budget_diffAdju") {
    sumEffort = params?.node?.aggData.sum_budget_e;
    adjustmentEffort = params?.node?.aggData.sum_budget_effAdju;
  }  else if (params?.column?.colDef?.colId === "sum_forecast_diffAdju") {
    sumEffort = params?.node?.aggData.sum_forecast_e;
    adjustmentEffort = params?.node?.aggData.sum_forecast_effAdju;
  }else {
    sumEffort = params?.node?.aggData[sumEffortColumn];
    adjustmentEffort = params?.node?.aggData[effortAdjustmentColumn];
  }
  if (!sumEffort && !adjustmentEffort) {
    differenceValue = 0;
  }
  if (!sumEffort) {
    differenceValue = 100;
  }
  if (!adjustmentEffort) {
    differenceValue = 100;
  }
  if(sumEffort === 0 && adjustmentEffort===0){
    differenceValue = 0;
  }
  if(sumEffort > 0 && adjustmentEffort === 0){
    differenceValue = -100;
  }
  if(sumEffort === 0 && adjustmentEffort > 0){
    differenceValue = 100;
  }
  if (sumEffort > 0 && adjustmentEffort > 0) {
    differenceValue = ((adjustmentEffort - sumEffort) / sumEffort) * 100;
  }
  return differenceValue ? differenceValue.toFixed(2) : 0;
}

export async function exportResultAsCsvUtils(id, granularity, startDate, endDate, token, dispatch, mhe, role) {
  const from = formatDateToApiFormat(startDate);
  const to = formatDateToApiFormat(endDate);
  const url = `/plans/${id}/exportcsv?granularity=${granularity}&includeMhe=${mhe}&includeRole=${role}&from=${from}&to=${to}`;
  await fetchData(withUrl(url).andToken(token).setFileDownload(`plan_${id}_${from}_${to}.csv`), dispatch);
}

// Fetch calculation for activities and mhe
export async function fetchCalculations(
  filter: FilterType = {},
  token: string,
  dispatch: Function,
  graphPage = false,
  storeData = true,
) {
  if (!graphPage) dispatch(calculationStarted());

  const { planningParametersId, startDate, endDate, granularity, includeMhe, includeBaseLine, includeForecast, includeRole, includeWeekStartAsSunday, includeVolumeCategory } = filter;
  const url =
    `/planningParameters/${planningParametersId}/calculate?` +
    `dateFrom=${startDate.toISODate()}&` +
    `dateTo=${endDate.toISODate()}&` +
    `granularity=${granularity}&` +
    `includeMhe=${(includeMhe && includeMhe.toString()) || 'false'}&` +
    `includeRole=${(includeRole && includeRole.toString()) || 'false'}&` +
    `includeForecast=${(includeForecast && 'true') || 'false'}&` +
    `includeBaseLine=${(includeBaseLine && 'true') || 'false'}&`+
    `includeWeekStartAsSunday=${(includeWeekStartAsSunday && granularity === 'WEEK' && 'true') || 'false'}&`+
    `includeVolumeCategory=${(includeVolumeCategory && 'true') || 'false'}`
  const response = await fetchData(withUrl(url).andToken(token), dispatch);
  if (response.isOk) {
    storeData && dispatch(storeResults(response.data));
    return response.data;
  }
}

export async function fetchMasterPlanCalculations(
filter: FilterType = {},
  token: string,
  dispatch: Function,
  graphPage = false,
  storeData = true,
) {
  if (!graphPage) dispatch(calculationStarted());
  const result = [];
  const vcRaw = [];
  const { planningParametersId, startDate, endDate, granularity, includeMhe, includeBaseLine, includeForecast, includeRole, includeWeekStartAsSunday, includeVolumeCategory, planIds, planData } = filter;
  const plansLength = filter?.planData?.length;
  const dataToCalucalte = filter && filter.planData.filter((p) => {
    if(plansLength === 1) return true;
    return ((startDate >= DateTime.fromISO(p.validFrom)) && (startDate <= DateTime.fromISO(p.validTo))) || ((endDate >= DateTime.fromISO(p.validFrom)) && (endDate <= DateTime.fromISO(p.validTo)))
  });
  const sortByDate = (a, b) => (a.validFrom > b.validFrom) ? 1 : ((b.validFrom > a.validFrom) ? -1 : 0);
  dataToCalucalte?.sort(sortByDate);
  await Promise.all(dataToCalucalte && dataToCalucalte.map(async (p)=>{
    var dateFrom = startDate.toFormat('MM/dd/yyyy');
    var dateTo = endDate.toFormat('MM/dd/yyyy');
    var dateValidFrom = DateTime.fromISO(p.validFrom).toFormat('MM/dd/yyyy');
    var dateValidTo = DateTime.fromISO(p.validTo).toFormat('MM/dd/yyyy');
    var from = DateTime.fromFormat(dateFrom, 'MM/dd/yyyy');//new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);  // -1 because months are from 0 to 11
    var to = DateTime.fromFormat(dateTo, 'MM/dd/yyyy');
    var checkOne = DateTime.fromFormat(dateValidFrom, 'MM/dd/yyyy');
    var checkTwo = DateTime.fromFormat(dateValidTo, 'MM/dd/yyyy');
    const dateFromApi = (from >= checkOne && from >= checkTwo) ? startDate.toISODate() : (!(from >= checkOne) && from >= checkTwo) ? startDate.toISODate() : ((from >= checkOne) && !(from >= checkTwo)) ? startDate.toISODate(): p.validFrom; 
    const dateToApi = (to >= checkOne && to >= checkTwo) ? p.validTo : (!(to >= checkOne) && (to >= checkTwo)) ? endDate.toISODate() : ((to >= checkOne) && !(to >= checkTwo)) ? endDate.toISODate(): endDate.toISODate();
    const url =
    `/planningParameters/${p.planingParameterId}/calculate?` +
    `dateFrom=${dateFromApi}&` +
    `dateTo=${dateToApi}&` +
    `granularity=${granularity}&` +
    `includeMhe=${(includeMhe && includeMhe.toString()) || 'false'}&` +
    `includeRole=${(includeRole && includeRole.toString()) || 'false'}&` +
    `includeForecast=${(includeForecast && 'true') || 'false'}&` +
    `includeBaseLine=${(includeBaseLine && 'true') || 'false'}&`+
    `includeWeekStartAsSunday=${(includeWeekStartAsSunday && granularity === 'WEEK' && 'true') || 'false'}&`+
    `includeVolumeCategory=${(includeVolumeCategory && 'true') || 'false'}`
  const response = await fetchData(withUrl(url).andToken(token), dispatch);
  if(response.isOk){
    const output = response.data;
    vcRaw.push([...output.volumeCategoryParameters]);
    output.volumeCategoryParameters =  recalculateVolumeCategoryParametersMasterPlan(output.volumeCategoryParameters)
    output.name = planData && planData.filter((pd)=> pd.planingParameterId === p.planingParameterId)[0].planName || '';
    output.planingParameterId = p.planingParameterId;
    result.push(output);
    output.validFrom = p.validFrom;
  }
  }))
    result.sort(sortByDate)
    const sortedPlanNames = result?.map((r)=>r?.planingParameterId);
    vcRaw?.sort( ( a, b ) => {
      const aColorIndex = sortedPlanNames?.indexOf( a[0]?.planningParametersId );
      const bColorIndex = sortedPlanNames?.indexOf( b[0]?.planningParametersId );
      return aColorIndex - bColorIndex;
    } );
    dispatch(storeResultVolumeRaw(vcRaw?.filter((v) => v.length != 0) || []));
    dispatch(storeResults(result));
    return result;
}

function translateTitles(titles: Array<Object>, formatMessage: Function) {
  if (!titles || titles.length === 0) return;

  titles.map(({ dict, key, msg, formatter }) => {
    let label = formatMessage(msg);
    if (formatter) {
      label = formatter.replace('{message}', label);
    }
    dict[key] = label;
  });
}

const excludeKeys = ['header', 'pageLabel', 'exportLabel', 'sheetLabel'];
function translateDataMessages(formatMessage) {
  const msgs = {};
  for (const key in calendarMessages) {
    msgs[key] = formatMessage(calendarMessages[key]);
  }
  for (const key in messages) {
    if (excludeKeys.indexOf(key) !== -1) {
      continue;
    }
    msgs[key] = formatMessage(messages[key]);
  }
  msgs.allDepartments = formatMessage(messages.allDepartments);
  return msgs;
}

const filterData = filter => item => {
  for (let i = 0; i < filter.length; i++) {
    if (filter[i].test(item.name)) return false;
  }
  return true;
};

function filterHeadsGraphData(results: Object, settings: Object) {
  if (!results || !settings) return;
  let filteredData = results.data;
  const filter = [];
  if (!settings.showEffort) {
    filter.push(/.*_effort_.*$/);
  }
  if (!settings.showHeads) {
    filter.push(/.*_heads_.*$/);
  }

  if (!settings.showHeadsOpt) {
    filter.push(/.*_headsOpt_.*$/);
  }
  if (filter.length > 0) {
    filteredData = results.data.filter(filterData(filter));
  }
  return filteredData;
}

export function filterAndStoreHeadsGraph(results: Object, columnSettings: Object, dispatch: Function) {
  if (!results) return;
  const filteredData = filterHeadsGraphData(results, columnSettings);
  dispatch(
    storeHeadsGraphFilteredData({
      filteredData,
      columnSettings,
    }),
  );
}

export async function calculateHeadsGraph(data: Object, settings: Object, columnSettings: Object, dispatch: Function) {
  const worker = await getWorker(
    'calculateHeads',
    () => new Worker(new URL('./calculation/workers/calculate.worker.js', import.meta.url), { type: 'module' }),
  );
  const results = await worker.start({ data, filter: settings });
  cleanPool();
  const filteredData = filterHeadsGraphData(results, columnSettings);
  dispatch(
    storeHeadsGraphData({
      settings,
      results,
      filteredData,
      columnSettings,
    }),
  );
}

// create Activities/MHE Plan Table Colum Defs and Data
export async function createColDefs(
  intl: Object,
  plan: Object,
  data: Object,
  filter: Object = {},
  editing: boolean,
  activitiesByPlan?: any,
  dispatch: Function,
) {
  if (data && data.volumeCategoryParameters) {
    const vcp = data.volumeCategoryParameters;
    dispatch(storeVolumeRaw(vcp));
  }
  if (data && data.volumeCategoryParameters) {
    const vcp = recalculateVolumeCategoryParameters(data.volumeCategoryParameters);
    dispatch(storeVolumeCP(vcp));
  }

  let isShift = false;
  let isDaily = false;
  let isDailyPR = false;
  let tt = T_TYPE.WZP;
  let isLabourAvailabilityTypePR = false;
  let displayAdjustments = true;
  let calculateValueType = 'FTE';
  if (plan) {
    const {
      planningParameters: { transformationType = T_TYPE.WZP, dayTransformationType, productivityRateGranularity, labourAvailabilityType, hasActivityAdjustments, calculateType } = {},
    } = plan;
    isShift = transformationType === T_TYPE.SHIFT;
    isDaily = dayTransformationType === DT_TYPE.DAILY;
    isDailyPR = productivityRateGranularity === GRANULARITY.DAY;
    isLabourAvailabilityTypePR = labourAvailabilityType == "DEPARTMENT";
    displayAdjustments = hasActivityAdjustments;
    calculateValueType = calculateType,
    tt = transformationType;
  }
  isShift = isShift && filter.granularity === 'HOUR';
  const byHour = (filter.includeBaseLine && filter.granularity === 'HOUR') || isShift;
  const settings = {
    ...filter,
    activitiesByPlan,
    editing,
    isShift,
    isDaily,
    isDailyPR,
    byHour,
    transformationType: tt,
    isLabourAvailabilityTypePR,
    displayAdjustments,
    calculateValueType
  };
  const props: Object = {
    formatTitles: [],
    locale: userLocale,
    messages: translateDataMessages(intl.formatMessage),
  };
  const worker = await getWorker(
    'calculateData',
    () => new Worker(new URL('./calculation/workers/calculate.worker.js', import.meta.url), { type: 'module' }),
  );
  const result = await worker.start({ data, filter: settings, props });
  cleanPool();
  const defsAndData = mergeDataAndDefs(data, props, settings, result);
  defsAndData.editing = editing;
  defsAndData.isShift = isShift;
  defsAndData.byHour = byHour;
  dispatch(storeResult({ defsAndData, settings }));
}

export async function resultcreateColDefs(
  intl: Object,
  plan: Object,
  data: Object,
  filter: Object = {},
  editing: boolean,
  activitiesByPlan?: any,
  dispatch: Function,
  planBasicDetails?: any
) {
  const {startDate, endDate} = filter;
  if (data && data.volumeCategoryParameters) {
    const vcp = data.volumeCategoryParameters;
    //dispatch(storeVolumeRaw(vcp));
  }
  if (data && data.volumeCategoryParameters) {
    const vcp = recalculateVolumeCategoryParameters(data.volumeCategoryParameters);
    //dispatch(storeVolumeCP(vcp));
  }

  let isShift = false;
  let isDaily = false;
  let isDailyPR = false;
  let tt = T_TYPE.WZP;
  let isLabourAvailabilityTypePR = false;
  let displayAdjustments = true;
  let calculateValueType = 'FTE';
  const plansLength = filter?.planData?.length;
  const dataToCalucalte = filter && filter.planData.filter((p) => {
    if(plansLength === 1) return true;
    //return ((DateTime.fromISO(p.validFrom) >= filter?.startDate) && (DateTime.fromISO(p.validFrom) <= filter?.endDate)) || ( (DateTime.fromISO(p.validTo) >= filter?.startDate) && (DateTime.fromISO(p.validTo) <= filter?.endDate));
    return ((startDate >= DateTime.fromISO(p.validFrom)) && (startDate <= DateTime.fromISO(p.validTo))) || ((endDate >= DateTime.fromISO(p.validFrom)) && (endDate <= DateTime.fromISO(p.validTo)))
  });
  if (plan) {
    const {
      planningParameters: { transformationType = T_TYPE.WZP, dayTransformationType, productivityRateGranularity, labourAvailabilityType, hasActivityAdjustments, calculateType } = {},
    } = plan;
    const ppIds = dataToCalucalte?.map((d)=>d.planingParameterId);
    const fetchedPlansData = planBasicDetails?.filter((p)=> ppIds.includes(p.planningParametersId));
    const calculateValue = [...new Set(dataToCalucalte?.map((p) => p.calculateType))];
    const adjustmentsValue = [...new Set(fetchedPlansData?.map((p) => p?.planningParameters?.hasActivityAdjustments))];
    isShift = transformationType === T_TYPE.SHIFT;
    isDaily = dayTransformationType === DT_TYPE.DAILY;
    isDailyPR = productivityRateGranularity === GRANULARITY.DAY;
    isLabourAvailabilityTypePR = planBasicDetails?.every((p) => p.planningParameters.labourAvailabilityType == "DEPARTMENT");
    displayAdjustments = adjustmentsValue?.length > 1 ? null : adjustmentsValue[0],
    calculateValueType = calculateValue?.length > 1 ? null : calculateValue[0],
    tt = transformationType;
  }
  isShift = isShift && filter.granularity === 'HOUR';
  const byHour = (filter.includeBaseLine && filter.granularity === 'HOUR') || isShift;
  const settings = {
    ...filter,
    activitiesByPlan,
    editing,
    isShift,
    isDaily,
    isDailyPR,
    byHour,
    transformationType: tt,
    isLabourAvailabilityTypePR,
    displayAdjustments,
    calculateValueType
  };
  const props: Object = {
    formatTitles: [],
    locale: userLocale,
    messages: translateDataMessages(intl.formatMessage),
  };
  const worker = await getWorker(
    'calculateData',
    () => new Worker(new URL('./calculation/workers/calculate.worker.js', import.meta.url), { type: 'module' }),
  );
  const result = await worker.start({ data, filter: settings, props });
  cleanPool();
  const defsAndData = mergeDataAndDefs(data, props, settings, result);
  defsAndData.editing = editing;
  defsAndData.isShift = isShift;
  defsAndData.byHour = byHour;
  defsAndData.name = data.name;
  defsAndData.isShiftFlag = calculateValueType;
  defsAndData.planingParameterId = data.planingParameterId;
  
  return (({ defsAndData, settings }));
}

export function parseDay(colDef: Object, granularity: string) {
  const { week, day } = colDef;
  if (granularity === GRANULARITY.WEEK) {
    return week;
  }
  return day;
}

export function flatCols(colDef: Array<Object>) {
  let flattenCols = [];
  colDef.map(col => {
    if (col.children) {
      flattenCols = flattenCols.concat(flatCols(col.children));
    } else {
      flattenCols.push(col);
    }
  });
  return flattenCols;
}

export const COL_TYPE = { prodRate: 'pr', volume: 'v', effort: 'e' };

// get only diff for Overrides
async function saveDiffActivities(values: Object, settings: Object, token: string, dispatch: Function) {
  const { overrides, mheOverrides } = values;
  const overrideList = Object.values(overrides);
  const mheOverridesList = Object.values(mheOverrides);
  if (overrideList.length === 0 && mheOverridesList.length === 0) {
    return;
  }
  dispatch(calculationStarted());
  const { planningParametersId } = settings;
  const url = `/planningParameters/${planningParametersId}/overrides/`;
  let userChangeId = null;
  const overrideResult = await fetchData(withUrl(url).post({
    list: overrideList,
    mheList: mheOverridesList,
  }).andToken(token), dispatch);
  if (overrideResult.isOk) {
    userChangeId = overrideResult.data.userChangeId;
  }
  return userChangeId;
}

export async function saveOverrides(
  values: Object,
  initialValues: Object,
  settings: Object,
  token: string,
  dispatch: Function,
  recalculate: boolean,
) {
  // save activities
  const userChangeId = await saveDiffActivities(values, settings, token, dispatch);
  const diffPP = diffPlanningParameters(initialValues, values);
  if (diffPP && Object.keys(diffPP).length > 0) {
    // other changes
    const payload = {
      planningParameters: diffPP,
    };
    const url = `/plans/${values.id}/saveAll`;
    const response = await fetchData(withUrl(url).post(payload).andToken(token), dispatch);
    const plan = (response.isOk && convertEntityWithPlanningParametersFromApi(response.data)) || null;
    plan && dispatch(storePlanAction(plan));
  }
  if (recalculate) {
    await fetchCalculations(settings, token, dispatch);
  }
  return userChangeId;
}

export async function undoChanges(
  planningParametersId: string,
  userChangeId: string | number,
  token: string,
  dispatch: Function,
) {
  dispatch(calculationStarted());
  const url = `/planningParameters/${planningParametersId}/change/${userChangeId}/revert`;
  const response = await fetchData(withUrl(url).post().andToken(token), dispatch);
  return response;
}

export function cellCallBack(params, data, calculateValueType = 'FTE', granularity = 'MONTH') {
  const {
    node: { group, field, key },
    column: { colId },
    value,
  } = params;
  if (group && value && colId === 'department') {
    if (field === 'groupKey') {
      let val = key.split(' || ');
      val = (val.length > 0 && val[0]) || key;
      return ` ----> ${val}`;
    }
    if (field === 'mhe') {
      return ` -------> ${key}`;
    }
  }
  if(params?.node?.rowPinned === 'bottom' && colId === 'shift' && granularity === 'HOUR'){
    return params?.node?.data?.activity;
  }
  if(params?.node?.data?.activity === "Discrepancy %" && params?.column?.colDef?.pinned !== true){
    return value === 100 ? `100%` : value === 0 ? '0%' : value ? `${(value).toFixed(2)}%` : null;
  }
  if((params?.column?.colDef?.unit === "sah" || params?.column?.colDef?.unit === "saf" || params?.column?.colDef?.unit === "sas" || params?.column?.colDef?.unit === "diff" || params?.column?.colDef?.unit === "fteDiff" || params?.column?.colDef?.unit === "staffDiff")){
    if(params?.column?.colDef?.unit === "sah" && (params?.node?.field ==="department")){
      const sah = calculateLabourAvailabilityValuesExcel(params, data, granularity, 'calculatedHoursEffort');
      return sah;
    }
    if(params?.column?.colDef?.unit === "saf" && (params?.node?.field ==="department")){
      const saf = calculateLabourAvailabilityValuesExcel(params, data, granularity, 'calculatedFte');
      return saf;
    }
    if(params?.column?.colDef?.unit === "sas" && (params?.node?.field ==="department")){
      const sas = calculateLabourAvailabilityValuesExcel(params, data, granularity, 'calculatedNumberOfStaff');
      return sas;
    }
    if(params?.column?.colDef?.unit === "diff" && (params?.node?.field ==="department") && (params?.node?.key !== "All Departments")){
      let differnce;
      const currentHours = params.value || 0;
      const availableHours = calculateLabourAvailabilityValuesExcel(params, data, granularity, calculateValueType === 'FTE' ? 'calculatedFte' : 'calculatedNumberOfStaff') || 0;
      if (availableHours === '') {
        return '';
      }
      if (availableHours == 0.000 && currentHours == 0.000) {
        differnce = 0;
        return '0%'
      }
      if (availableHours === 0 && currentHours == 0) {
        differnce = 0;
        return '0%'
      }
      if (availableHours === 0 && currentHours === null) {
        differnce = 0;
        return '0%'
      }
      if (availableHours === 0 || availableHours == 0.000) {
        differnce = -100;
        return '-100%';
      }
      if (currentHours == 0) {
        differnce = 100;
        return '100%'
      }
      if (currentHours && availableHours) {
        differnce = ((availableHours - currentHours) / availableHours) * 100;
      }
      return `${Number(differnce).toFixed(2)}%`;
    }
    if((params?.column?.colDef?.unit === "fteDiff" || params?.column?.colDef?.unit === "staffDiff") && (params?.node?.field ==="department") && (params?.node?.key !== "All Departments")){
      const currentHours = params.value || 0;
      let differnce = 0;
      const availableHours = calculateLabourAvailabilityValuesExcel(params, data, granularity, calculateValueType === 'FTE' ? 'calculatedFte' : 'calculatedNumberOfStaff') || 0;
      if (currentHours && availableHours) {
        differnce = availableHours - currentHours;
      }
      return Number(differnce).toFixed(2);
    }
    return null;
  }
  
  if ((params?.column?.colDef?.colId === "sum_planned_diffAdju" || params?.column?.colDef?.colId === "sum_budget_diffAdju" || params?.column?.colDef?.colId === "sum_forecast_diffAdju") && (params?.node?.field === "activity" || params?.node?.field === "uom") && granularity === 'HOUR') {
    const val = calculateRowsDifferenceAdjustmentByHour(params);
    return val === 100 ? '100%' : val === 0 ? '0%' : `${val}%`
  }
  if((params?.column?.colDef?.unit === "diffAdju") && (params?.node?.field ==="department" || params?.node?.field === "customer")){
    const val = calculateRowsDifferenceAdjustment(params, params?.column?.userProvidedColDef?.dataSet);
    return val == 100 ? '100%' : val == 0  ? '0%' : `${calculateRowsDifferenceAdjustment(params, params?.column?.userProvidedColDef?.dataSet)}%`
  }

  if (group && value && colId.endsWith('_v') && field !== 'activity') {
    return '';
  }

  if (colId === 'wzp' && typeof value === 'object') {
    return (value && value.workZonePeriodName) || '';
  }

  if (typeof value === 'number') {
    if(params?.column?.colDef?.unit === "diffAdju"){
      const valueToDisplay = formatNumberForExport(value);
      return valueToDisplay === 0 ? '0%' : `${Number(valueToDisplay).toFixed(2)}%`
    }
    return formatNumberForExport(value);
  }

  if (typeof value === 'string') {
    if(params?.node?.rowPinned === 'bottom'){
      if(params?.column?.colDef?.unit === "diffAdju"){
        return value == '100.00' ? '100%' : (value === '0' || value == '0.00') ? '0%' : `${Number(value).toFixed(2)}%`;
      }
      return value;
    } 
    // Remove "regionalConfigurationName" if present, it is separated by "||"
    let newValue = value.split(' ||')[0];

    // Remove "uom" if present
    if (newValue.endsWith(')') && newValue.indexOf('(') > -1) {
      newValue = newValue.substring(0, newValue.indexOf('('));
    }

    return newValue;
  }
  if(colId === 'wzp' && params?.node?.rowPinned === 'bottom'){
    return params?.node?.data?.activity;
  }
  return value;
}

export function formatNumberForExport(value, fixedLength) {
  const fixed = fixedLength || 3;
  const isFloat = typeof value === 'number' && !Number.isInteger(value);
  // Format only such number, which have fractional part > 3. Dividing by one returns '0.xxx'
  if (isFloat && (value % 1).toString().length > fixed + 2) {
    return value.toFixed(fixed);
  }
  return value;
}

export function roletableHeight(
  result: Object,
  totalLength = 0,
  showAllTotals: boolean,
  granularity: string,
  isShift = false,
  isMHE = false,
  newRowData
) {
  const lineHeight = 30;
  const rowLineHeight = 28;
  let headerHeight = 4 * lineHeight;
  if (granularity === 'DAY') headerHeight += 40;
  else if (granularity === 'WZP') headerHeight += 80;
  else if (granularity === 'HOUR') headerHeight += 120;

  const bottomHeight = ((showAllTotals && totalLength) || 3) * lineHeight;

  const groupsRows = {};
  if (result && result.data) {
    result.data.map(row => {
      groupsRows[row.department] = true;
      groupsRows[row.customer || 'all'] = true;
      if (isShift) {
        groupsRows[row.activity || 'all_a'] = true;
      }
      if (isMHE) {
        groupsRows[row.mhe || 'all_m'] = true;
      }
    });
  }

  const groupLength = Object.keys(groupsRows).length;
  let bodyHeight = ((newRowData?.length) || 1) * 30;

  if (result && result.data && result.data.length > 0) {
    bodyHeight = result.data.length * rowLineHeight + groupLength * lineHeight;
  }

  let aproxHeight = headerHeight + bodyHeight;
  if (aproxHeight > 600) aproxHeight = 600;
  else if (aproxHeight < 240) aproxHeight = 240;
  return aproxHeight + bottomHeight;
}

export function tableHeight(
  result: Object,
  totalLength = 0,
  showAllTotals: boolean,
  granularity: string,
  isShift = false,
  isMHE = false,
) {
  const lineHeight = 30;
  const rowLineHeight = 28;
  let headerHeight = 4 * lineHeight;
  if (granularity === 'DAY') headerHeight += 3 * lineHeight;
  else if (granularity === 'WZP') headerHeight += 5 * lineHeight;
  else if (granularity === 'HOUR') headerHeight = 10 * lineHeight;

  const bottomHeight = ((showAllTotals && totalLength) || 3) * lineHeight;

  const groupsRows = {};
  if (result && result.data) {
    result.data.map(row => {
      groupsRows[row.department] = true;
      groupsRows[row.customer || 'all'] = true;
      if (isShift) {
        groupsRows[row.activity || 'all_a'] = true;
      }
      if (isMHE) {
        groupsRows[row.mhe || 'all_m'] = true;
      }
    });
  }

  const groupLength = Object.keys(groupsRows).length;
  let bodyHeight = headerHeight;

  if (result && result.data && result.data.length > 0) {
    bodyHeight = result.data.length * rowLineHeight + groupLength * lineHeight;
  }

  let aproxHeight = headerHeight + bodyHeight;
  if (aproxHeight > 600) aproxHeight = 600;
  else if (aproxHeight < 240) aproxHeight = 240;
  return aproxHeight + bottomHeight;
}

// I dont't save column state for now, it should be done only for pinned columns
export function extractTableConfig(gridApi: Object) {
  if (!gridApi) return;
  const sortModel = gridApi.api.getSortModel();
  const filterModel = gridApi.api.getFilterModel();
  const paginationModel = { currentPage: gridApi.api.paginationGetCurrentPage() };
  return { sortModel, filterModel, paginationModel };
}

export function setTableConfig(gridApi: Object, config: Object) {
  if (!gridApi) {
    return;
  }
  // reset to default
  if (!config) {
    gridApi.api.setFilterModel(null);
    gridApi.api.setSortModel([]);
    return;
  }

  const { sortModel, filterModel, paginationModel } = config;
  if (sortModel) {
    gridApi.api.setSortModel(sortModel);
  }
  if (filterModel) {
    gridApi.api.setFilterModel(filterModel);
  }
  if (paginationModel && paginationModel.currentPage !== undefined) {
    gridApi.api.paginationGoToPage(paginationModel.currentPage);
  }
}

export function isUserView(view: string) {
  return view === USER_VIEWS.USER_VIEW;
}

export function getGraphUnit(params: Object) {
  const isEffort = /^.*_effort.*$/.test(params.seriesId);
  if (isEffort) return 'h';
  const isHeadsOpt = /^.*_headsOpt.*$/.test(params.seriesId);
  if (isHeadsOpt) return '';

  const isHeads = /^.*_heads.*$/.test(params.seriesId);
  if (isHeads) return '';

  return 'fte';
}

export function getSectionName(itemName: string) {
  return itemName.match(/^.*_([^_]*)_.*$/)[1];
}

export function saveToStorageById(login: string, id: string, values, object: string) {
  const key = sha256(`${id}.${object}.${login}`).toString();

  let vals = values;
  if (values && values.planningParametersId) {
    vals = { ...values };
    delete vals.planningParametersId;
  }

  saveToLocalstorage(key, vals);
}

export function loadFromStorageById(login: string, id: string, object: string) {
  const key = sha256(`${id}.${object}.${login}`).toString();
  return loadFromLocalstorage(key);
}

/* eslint-disable react/prefer-stateless-function */
export function loadFromStorage(key: string) {
  let value = localStorage.getItem(key);
  if (value) value = JSON.parse(value);
  return value;
}


export function minMaxDates(dates) {
  let minDate = null;//new Date(275760, 8, 13);
  let maxDate = null//new Date(1970, 1, 1);
  dates && dates.map(date => {
      minDate = minDate < date.validFrom ? minDate : date.validFrom;
      maxDate = maxDate > date.validTo ? maxDate : date.validTo;
  });
  return {minDate, maxDate}
};

async function saveMasterPlanDiffActivities(values: Object, settings: Object, token: string, dispatch: Function) {
  let userChangeId = [];
  const { overrides, mheOverrides } = values;
  const overrideList = Object.values(overrides);
  const res = Object.groupBy(overrideList, e => e.planingParameterId);
  const mheOverridesList = Object.values(mheOverrides);
  if (overrideList.length === 0 && mheOverridesList.length === 0) {
    return;
  }
  dispatch(calculationStarted());
  if (overrideList.length > 0 && mheOverridesList.length === 0) {
    await handleSaveOverrides(res);
    async function handleSaveOverrides(list) {
      for (const o in list) {
        const url = `/planningParameters/${o}/overrides/`;
        const overrideResult = await fetchData(withUrl(url).post({
          list: res[o],
          mheList: mheOverridesList,
        }).andToken(token), dispatch);
        if (overrideResult.isOk) {
          userChangeId.push({userChangeId: overrideResult.data.userChangeId, planingParameterId : o});
        }
      }
    }
    return userChangeId;
  }
}

export async function saveMasterPlanOverrides(
  values: Object,
  initialValues: Object,
  settings: Object,
  token: string,
  dispatch: Function,
  recalculate: boolean,
) {
  const plansData = [];
  // save activities
  const userChangeId = await saveMasterPlanDiffActivities(values, settings, token, dispatch);
  const diffPP = diffPlanningParameters(initialValues, values);
  const overrideList = diffPP && Object.keys(diffPP) && Object.keys(diffPP).length && Object.values(diffPP?.volumes);
  const res = overrideList && Object.groupBy(overrideList, e => e.planId);
  if (diffPP && Object.keys(diffPP).length > 0) {
    if (overrideList.length > 0) {
      await handleSaveOverrides(res);
      async function handleSaveOverrides(list) {
        for (const o in list) {
          const url = `/plans/${o}/saveAll`
          const overrideResult = await fetchData(withUrl(url).post({planningParameters : {volumes : list[o]}}).andToken(token), dispatch);
          if(overrideResult.isOk){
            const plan = await convertEntityWithPlanningParametersFromApi(overrideResult.data, true);
            plansData.push(plan);
          }
        }
      }
      dispatch(storePlanBasicDetails(plansData))
    }
  }
  if (recalculate) {
    await fetchMasterPlanCalculations(settings, token, dispatch);
  }
  return userChangeId;
}

export async function undoMasterPlanResultChanges(
  userChangeId: string | number,
  token: string,
  dispatch: Function,
) {
  let isEverthingSucess =false;
  dispatch(calculationStarted());
  await handleRevertChanges();
  async function handleRevertChanges(){
    for(const o of userChangeId){
      const url = `/planningParameters/${o.planingParameterId}/change/${o.userChangeId}/revert`;
      const response = await fetchData(withUrl(url).post().andToken(token), dispatch);
      if(response.isOk){
        isEverthingSucess = true;
      }
    }
  }
  return isEverthingSucess;
}